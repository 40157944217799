import React from 'react';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import SubWorkspace from '../Components/SubWorkspace';

const SubWork = () => {
    return (
        <>
            <Header />
            <Navbar />
            <SubWorkspace/>
            
        </>
    )
}
export default SubWork;