import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import styles from './header.module.css';

const Header = () => {
  const [isSubmenuVisible, setIsSubmenuVisible] = useState<any>(false);
  const submenuRef = useRef<any>(null);

  const handleMouseEnter = () => {
    setIsSubmenuVisible(true);
  };

  const handleClickOutside = (event:any) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setIsSubmenuVisible(false);
    }
  };

  useEffect(() => {
    if (isSubmenuVisible) {
      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isSubmenuVisible]);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src="/logo.png" alt="Company Logo" width={30} height={30} />
      </div>
      <div className={styles.nav}>
        <Link to="/" className={styles.homeButton}>
          <img src="/home.png" alt="Home Logo" width={25} height={25} />
        </Link>
        <div className={styles.admin} onClick={handleMouseEnter} ref={submenuRef}>
          <img src="/default.png" alt="Admin" width={25} height={25} className={styles.adminImage} />
          {isSubmenuVisible && (
            <div className={styles.submenu}>
              <div className={styles.arrowUp}></div>
              <Link to="/update-profile" className={`${styles.submenuItem} link-submenu`}>
                <img className={styles.submenuImg} src="/update.png" alt="Update Logo" width={18} height={18} />
                <span className={styles.submenuText}>Update Profile</span>
              </Link>
              <Link to="/logout" className={`${styles.submenuItem} link-submenu`}>
                <img className={styles.submenuImg} src="/logout.png" alt="Logout Logo" width={17} height={17} />
                <span className={styles.submenuText}> Logout</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
