import { combineReducers } from "redux";

import loggedReducer from "./UserInfo/isLogged";

import DataSetReducer from "./DataSet/dataset";

const allReducers = combineReducers({
  isLogged: loggedReducer,
  dataSetState: DataSetReducer
});

export type RootState = ReturnType<typeof allReducers>;
export default allReducers;
