import React, { useState, useEffect } from "react";
import styles from "./subworkspace.module.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";


const SubWorkspaceDetails = () => {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [workspaceName, setWorkspaceName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const { parentId } = useParams();
  const [parentWorkspaceName, setParentWorkspaceName] = useState("");
  const [childWorkspaceName, setChildWorkspaceName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [subWorkspaces, setSubWorkspaces] = useState([]);
  const navigate = useNavigate();
  const [mode] = useState("New");

  const id_parent = localStorage.getItem('pid');

  useEffect(() => {
    const fetchParentWorkspaceName = async () => {
      if (parentId) {
        try {
          const response = await axios.get(
            `https://dev.silzila.com/api/workspace/${id_parent}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          console.log("Parent Workspace Response:", response.data);

          const foundWorkspace = response.data.find(
            (workspace:any) => workspace.id === parentId
          );

          if (foundWorkspace) {
            setParentWorkspaceName(foundWorkspace.parentWorkspaceName);
            setChildWorkspaceName(foundWorkspace.name);
            setOwnerName(foundWorkspace.createdBy);
          } else {
            console.error("Workspace not found");
          }
        } catch (error) {
          console.error("Error fetching parent workspace:", error);
        }
      }
    };

    fetchParentWorkspaceName();
  }, [id_parent]);

  useEffect(() => {
    const fetchSubWorkspaces = async () => {
      if (parentId) {
        try {
          const response = await axios.get(
            `https://dev.silzila.com/api/workspace/${parentId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          console.log("Sub Workspaces Response:", response.data);

          // Assuming response.data.subWorkspaces contains the list of sub-workspaces
          setSubWorkspaces(response.data || []);
          
        } catch (error) {
          console.error("Error fetching sub-workspaces:", error);
        }
      }
    };

    fetchSubWorkspaces();
  }, [parentId]);

  const handleCreateItem = async () => {
    let url;
    let data;

    switch (modalType) {
      case "Flatfile":
      case "Playbook":
      case "DB Connections":
      case "Dataset":
        url = "https://dev.silzila.com/api/content/create";
        data = { name: workspaceName };
        break;
      default:
        return;
    }

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (response.status === 200) {
        console.log(`${modalType} created successfully`);

        setWorkspaceName("");
        setIsModalOpen(false);
      } else {
        console.error(`Failed to create ${modalType}`);
      }
    } catch (error) {
      console.error(`Error creating ${modalType}:`, error);
    }
  };

  const getImageSrc = (contentType:any) => {
    switch (contentType) {
      case 1:
        return "/database.png";
      case 2:
        return "/dset.png";
      case 3:
        return "/files.png";
      case 4:
        return "/workspace.png";
      default:
        return ''; // Return a default image or an empty string if no match
    }
  };
   const getContentTypeLabel = (contentType:any) => {
    
    switch (contentType) {
      case 1:
        return "sub-workspace";
      case 2:
        return "dbconnection";
      case 3:
        return "dataset";
      case 4:
        return "flatfile";
      case 5:
        return "playbook";
      default:
        return "unknown";
    }
  };

  const navigateToPages = (name:string) =>{

    switch (name) {     
      case "DB Connections":    
        navigate(`/newdataconnection/${parentId}`, { state: { mode: mode, parentId : parentId } });
      break;
      default:
        return;
    }   
  };


  return (
    <>     
      <Link to="/">
        <button className={styles.backLink}>
          <img className={styles.homeIcon} src="/home_grey.png" alt="Home" width={20} height={20} />
          <span className={styles.homeText}>Home</span>
          <span >&nbsp;&nbsp;/</span>
        </button>
      </Link>

      <Link to={`/workspace/${id_parent}`}>
        <button style={{ width: "80px" }} className={styles.backLink}>{parentWorkspaceName}</button>
      </Link>

      <span className={styles.WorkspaceName} >&nbsp;&nbsp;/&nbsp;&nbsp;{childWorkspaceName}</span>

      <div className={styles.heading}>
        <img src="/folder_work.png" height={60} width={60} />
        <div>
          <h4>{childWorkspaceName}</h4>
          <p>Owner {ownerName}</p>
        </div>
        <div>
          {/* Potentially more content here */}
        </div>
      </div>
      <div className={styles.actionButtons}>
        <div className={styles.dropdown}>
          <button className={styles.createWorkspace}>
            <img
              className="addIcon addIconDefault"
              src="/add_green.png"
              alt="Add Users Logo"
              width={20}
              height={20}
            />
            &nbsp;New
          </button>
          <div className={styles.dropdownContent}>
            {[
              { name: "DB Connections", icon: getImageSrc(1) },
              { name: "Dataset", icon: getImageSrc(2) },
              { name: "Flatfile", icon: getImageSrc(3) },
              { name: "Playbook", icon: getImageSrc(4) },
            ]
              .map(
                (item) => (
                  <button
                    key={item.name}
                    onClick={() => {
                      setModalType(item.name);
                      navigateToPages(item.name);
                      
                      if(item.name === "Workspace"){
                        setIsModalOpen(true);
                      }
                    }}
                  >
                    <img
                      src={item.icon}
                      alt={`${item.name} Icon`}
                      height={20}
                      width={20}
                      style={{ marginRight: "15px" }}
                    />
                    {item.name}
                  </button>
                )
              )}
          </div>
        </div>
      </div>
      <div className={styles.workspaceList}>
         <table className={styles.workspaceTable}>
            <thead>
              <tr>
                <th style={{width:"80px",paddingLeft:"60px"}}></th>
                <th style={{width:"300px",paddingLeft:"50px"}}>Name</th>
                <th style={{width:"250px",paddingLeft:"50px"}}>Type</th>
                <th style={{width:"250px",paddingLeft:"50px"}}>Modified</th>
                <th style={{width:"100px",paddingLeft:"50px"}}>Actions</th>
              </tr>
            </thead>
          </table>
        {subWorkspaces.length === 0 ? (
          <p className={styles.noWorkspaces}>
            No contents created in this workspace. Please click on the New button to add contents .
          </p>
        ) : (
          <table className={styles.workspaceTableBody}>
            {/* <thead>
              <tr>
                <th style={{ width: "80px", paddingLeft: "60px" }}></th>
                <th style={{ paddingLeft: "50px" }}>Name</th>
                <th style={{ paddingLeft: "50px" }}>Type</th>
                <th style={{ paddingLeft: "50px" }}>Modified</th>
              </tr>
            </thead> */}
            <tbody>
              {subWorkspaces.map((workspace:any) => (
                <tr key={workspace.id}
                onMouseEnter={() => setHoveredRowId(workspace.id)}
                onMouseLeave={() => setHoveredRowId(null)}
                >
                  <td style={{ width: "80px", paddingLeft: "60px" }}>
                    <img
                      src={getImageSrc(workspace.contentType)}
                      alt="Content Type Icon"
                      height={20}
                      width={20}
                    />
                  </td>
                  <td style={{ width:"300px",paddingLeft: "50px" }}>{workspace.name}</td>
                  <td style={{ width:"250px",paddingLeft: "50px" }}>
                  {getContentTypeLabel(workspace.contentType)}
                  </td>
                  <td style={{ width:"250px",paddingLeft: "50px" }}>
                    {new Date(workspace.modifiedAt).toLocaleDateString()}
                  </td>
                  <td style={{width:"100px",paddingLeft:"50px"}}>
                    {workspace.roleId < 5 && (
                      <>
                      <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/edit.png"
                              : "/edit_white.png"
                          }
                          alt="Edit"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={`${styles.actionIcon} ${styles.editIcon}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            // openEditModal(
                            //   workspace.id,
                            //   workspace.name,
                            //   workspace.parentId
                            // );
                          }}
                          height={20}
                          width={20}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="tooltip-text">Rename </span>
                        </div>

                        <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/delete_red.png"
                              : "/delete_white.png"
                          }
                          alt="Delete"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={`${styles.actionIcon} ${styles.deleteIcon}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleDeleteWorkspace(workspace.id);
                            // deleteWorkspace(workspace.id,workspace.name)
                          }}
                          height={20}
                          width={20}
                        />&nbsp;&nbsp;&nbsp;
                         <span className="tooltip-text">Delete </span>
                         </div>

                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Name your {modalType}</h3>
            <input
              type="text"
              required
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder={`Enter ${modalType} name`}
            />
            <button className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>Cancel</button>
            <button lassName={styles.saveButton} onClick={handleCreateItem}>Create</button>

          </div>
        </div>
      )}  */}
       {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Name your {modalType}</h3>
            <input
              type="text"
              required
              // value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder={`Enter ${modalType} name`}
            />
            <div className="modal-buttons"> 
            <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            <button className="modal-create" onClick={handleCreateItem}>Create</button>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubWorkspaceDetails;


