import React from 'react';
import Header from '../Components/Header';
import Navbar from '../Components/Navbar';
import UserProf from '../Components/UserProf';

const UpdateProfile = () => {
  return (
    <>
      <Header />
      <Navbar />
      <UserProf />
    </>
  );
}

export default UpdateProfile;
