import React from 'react';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import WorkspaceList from '../Components/Workspace';


const  Workspace=()=>{   

    return(
        <>
            <Header/>
            <Navbar/>
            <WorkspaceList/>
           
        </>
    )
}

export default Workspace;