import React, { useState } from "react";
import useSWR from "swr";
import axios from "axios";
import styles from "./workspace.module.css";
import { Link, useNavigate } from "react-router-dom";

// const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhY2hpbnR5YS5yYWpAc2lsemlsYS5jb20iLCJhdWRpZW5jZSI6IndlYiIsImFjY2VzcyI6ImFjY291bnRfYWRtaW4iLCJpYXQiOjE3MjQ5MDg5MDEsInRlbmFudCI6InNpbHppbGEiLCJleHAiOjE3MjQ5NjY1MDF9.pYy1tnBjhJKAKzzWsPd5AdrYfL8ipDOYxu4-j-We7aQaNyI8COZYxDupyuhOsQ7grASCJyVbAVmTqgrzhD2VPQ"; // Retrieve token from local storage
// localStorage.setItem('accessToken', token)
// Define a fetcher function using axios
const fetcher = (url:any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

const WorkspaceList = () => {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [workspaceName, setWorkspaceName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [editWorkspaceId, setEditWorkspaceId] = useState(null);

  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
   // State for delete confirmation
   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
   const [workspaceIndexToDelete, setWorkspaceIndexToDelete] = useState(null);


  // Use SWR for fetching workspaces
  const { data: workspaces = [], error, isLoading, mutate } = useSWR(
    "https://dev.silzila.com/api/workspace",
    fetcher
  );
  const handleWorkspaceClick = (wid:any, wname:any) => {
    navigate(`/workspace/${wid}`, { state: { wname } });
};

  const handleCreateWorkspace = async () => {
    try {
      const response = await axios.post(
        "https://dev.silzila.com/api/workspace/create",
        {
          
          name: workspaceName,
          parentId: parentId || null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Workspace created successfully");
        mutate();
        setIsModalOpen(false); 
        setIsDropdownOpen(false);
        setWorkspaceName(""); 
      } else {
        console.error("Failed to create workspace");
      }
    } catch (error) {
      console.error("Error creating workspace:", error);
    }
  };

  const handleEditWorkspace = async () => {
    try {
      const response = await axios.put(
        "https://dev.silzila.com/api/workspace/update",
        {
          workspaceId: editWorkspaceId,
          name: workspaceName,
          parentId: parentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Workspace updated successfully");
        mutate(); 
        setIsEditModalOpen(false); 
        setWorkspaceName(""); 
        setEditWorkspaceId(null); 
      } else {
        console.error("Failed to update workspace");
      }
    } catch (error) {
      console.error("Error updating workspace:", error);
    }
  };

  const deleteWorkspace = (index:any,name:any) => {
    setWorkspaceIndexToDelete(index);
    setWorkspaceName(name);
    setShowDeleteConfirmation(true);
};
// const confirmDeleteUser = async () => {
//     try {
//         const userToDelete = users[userIndexToDelete];
//         if (!userToDelete.id) throw new Error("User ID is missing");

//         await axiosInstance.delete(`https://dev.silzila.com/api/user/delete/${userToDelete.id}`);
//         const updatedUsers = users.filter((_, i) => i !== userIndexToDelete);
//         setUsers(updatedUsers);
//     } catch (error) {
//         console.error('There was an error deleting the user:', error);
//         alert('Error deleting user. Please try again.');
//     } finally {
//         setShowDeleteConfirmation(false);
//         setUserIndexToDelete(null);
//     }
// };
const handleDeleteWorkspace = async () => {
  try {
    const response = await axios.delete(
      `https://dev.silzila.com/api/workspace/delete/${workspaceIndexToDelete}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.status === 200) {
      console.log("Workspace deleted successfully");
      mutate(); 
    } else {
      console.error("Failed to delete workspace");
    }
  } catch (error) {
    console.error("Error deleting workspace:", error);
  }
  finally {
    setShowDeleteConfirmation(false);
    setWorkspaceIndexToDelete(null);
}
};

const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setWorkspaceIndexToDelete(null);
};

  

  const openEditModal = (id:any, currentName:any, currentParentId:any) => {
    setEditWorkspaceId(id);
    setWorkspaceName(currentName);
    setParentId(currentParentId);
    setIsEditModalOpen(true);
  };

  const openAccessModal = (workspaceId:any) => {
    navigate(`/workspace/access/${workspaceId}`, { state: { workspaceId } });
  };

  if (error) return <p>Error loading workspaces.</p>;

  return (
    <>
      <Link to="/admin/users">
        <button className={styles.backLink}>Back</button>
      </Link>
      <div className={styles.actionButtons}>
  
      {workspaces.some((workspace:any) => workspace.roleId < 3) && (
        <div 
        className={styles.createWorkspaceDropdown}
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)}
      >
        <button
          className={styles.createWorkspace}
          // onClick={() => setIsModalOpen(true)}
        >
          <img
            className="addIcon addIconDefault"
            src="/add_green.png"
            alt="Add Users Logo"
            width={20}
            height={20}
          />
          &nbsp;New
        </button>

        {isDropdownOpen && (
          <div className={styles.dropdownMenu}>
            <button 
              className={styles.dropdownItem} 
              onClick={() => setIsModalOpen(true)}
            >
            <img src="folder_work.png" alt="Workspace Icon" width={17} height={17} className={styles.dropdownIcon}></img>  
            Workspace
              </button>
            </div>
          )}
        </div>
      )}
      </div>

      <div className={styles.workspaceList}>
        {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div> 
        </div>
        ) : workspaces.length === 0 ? (
          <p className={styles.noWorkspaces}>
            Currently, there are no workspaces created.
          </p>
        ) : (
          <table className={styles.workspaceTable}>
            <thead>
              <tr>
                <th style={{width:'80px'}}></th>
                <th style={{width:'500px'}}>Workspace</th>
                <th style={{width:'300px'}}>Created</th>
                <th style={{width:'250px'}}>Created by</th>
                <th style={{width:'200px'}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {workspaces.map((workspace:any) => (
                <tr
                  key={workspace.id}
                  onClick={() => handleWorkspaceClick(workspace.id, workspace.name)}
                  onMouseEnter={() => setHoveredRowId(workspace.id)}
                  onMouseLeave={() => setHoveredRowId(null)}
                >
                  <td style={{width:'80px',paddingLeft:"60px"}}>
                    <img src='/folder_work.png' className={styles.icon} height={20} width={20} alt="Folder Icon" />
                  </td>

                  <td className={styles.nameHyperlink}>{workspace.name}</td>
                  <td style={{width:'300px'}}>{workspace.createdAt}</td> 
                  <td style={{width:'250px'}}>{workspace.createdBy}</td>
                  <td style={{width:'200px'}}>
                    {workspace.roleId < 5 && (
                      <>
                      <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/edit.png"
                              : "/edit_white.png"
                          }
                          alt="Edit"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={`${styles.actionIcon} ${styles.editIcon}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            openEditModal(
                              workspace.id,
                              workspace.name,
                              workspace.parentId
                            );
                          }}
                          height={20}
                          width={20}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="tooltip-text">Rename </span>
                        </div>

                        <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/delete_red.png"
                              : "/delete_white.png"
                          }
                          alt="Delete"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={`${styles.actionIcon} ${styles.deleteIcon}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleDeleteWorkspace(workspace.id);
                            deleteWorkspace(workspace.id,workspace.name)
                          }}
                          height={20}
                          width={20}
                        />&nbsp;&nbsp;&nbsp;
                         <span className="tooltip-text">Delete </span>
                         </div>

                       <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/access.png"
                              : "/access_white.png"
                          }
                          alt="Access"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={styles.actionIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            openAccessModal(workspace.id);
                          }}
                          height={20}
                          width={20}
                        />
                        <span className="tooltip-text">Manage Access</span>
                        </div>
                      </>
                    )}
                  </td> 
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {showDeleteConfirmation && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={cancelDelete}>&times;</span>
                            <p>Are you sure you want to delete {workspaceName}?</p>
                            <div className="modal-buttons">
                                <button onClick={cancelDelete} className="modal-cancel">Cancel</button>
                                <button onClick={handleDeleteWorkspace} className="modal-confirm">Confirm</button>
                            </div>
                         </div>
                    </div>
                )}


      {/* Create Workspace Modal */}
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Create Workspace</h3>
            <input
              type="text"
              // value={""}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder="Workspace Name"
            />
            <button className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>Cancel</button>
            <button className={styles.saveButton} onClick={handleCreateWorkspace}>Create</button>
            
          </div>
        </div>
      )}

      {/* Edit Workspace Modal  */}
       {isEditModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Rename</h3>
            <input
              type="text"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder="Workspace Name"
            />
            <button className={styles.cancelButton} onClick={() => setIsEditModalOpen(false)}>Cancel</button>
            <button className={styles.saveButton} onClick={handleEditWorkspace}>Rename</button>
          </div>
        </div>
      )} 
      
    </>
  );
};

export default WorkspaceList;















// 'use client'
// import React, { useState } from "react";
// import useSWR from "swr";
// import axios from "axios";
// import styles from "./workspace.module.css";
// import { Link } from "react-router-dom";
// const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhY2hpbnR5YS5yYWpAc2lsemlsYS5jb20iLCJhdWRpZW5jZSI6IndlYiIsImFjY2VzcyI6ImFjY291bnRfYWRtaW4iLCJpYXQiOjE3MjM2MTczOTQsInRlbmFudCI6InNpbHppbGEiLCJleHAiOjE3MjM2NzQ5OTR9.w7A0D0KnMrrhwQV02-c6n4psUohlZw92C0t3JOlMkTqrcE9HuLuPVqhBNBQ-RbnNQNvGl_sGRy_DdIAIGgEacQ";
// localStorage.setItem('accessToken', token)
// // Define a fetcher function using axios
// const fetcher = (url) =>
//   axios
//     .get(url, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//         "Content-Type": "application/json",
//       },
//     })
//     .then((res) => res.data);

// const WorkspaceList = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [workspaceName, setWorkspaceName] = useState("");
//   const [editWorkspaceId, setEditWorkspaceId] = useState(null);
//   const [parentId, setParentId] = useState(null);
//   const [hoveredRowId, setHoveredRowId] = useState(null);

//   // Use SWR for fetching workspaces
//   const { data: workspaces = [], error, isLoading, mutate } = useSWR(
//     "https://dev.silzila.com/api/workspace",
//     fetcher
//   );

  // const handleCreateWorkspace = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://dev.silzila.com/api/workspace/create",
  //       {
  //         name: workspaceName,
  //         parentId: parentId || null,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       console.log("Workspace created successfully");
  //       // Mutate the SWR cache to update workspaces
  //       mutate();
  //       setIsModalOpen(false); // Close the modal
  //       setWorkspaceName(""); // Reset the workspace name
  //     } else {
  //       console.error("Failed to create workspace");
  //     }
  //   } catch (error) {
  //     console.error("Error creating workspace:", error);
  //   }
  // };

  // const handleEditWorkspace = async () => {
  //   try {
  //     const response = await axios.put(
  //       "https://dev.silzila.com/api/workspace/update",
  //       {
  //         workspaceId: editWorkspaceId,
  //         name: workspaceName,
  //         parentId: parentId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       console.log("Workspace updated successfully");
  //       mutate(); // Mutate the SWR cache to update workspaces
  //       setIsEditModalOpen(false);
  //       setWorkspaceName("");
  //       setEditWorkspaceId(null);
  //     } else {
  //       console.error("Failed to update workspace");
  //     }
  //   } catch (error) {
  //     console.error("Error updating workspace:", error);
  //   }
  // };

  // const handleDeleteWorkspace = async (id) => {
  //   try {
  //     const response = await axios.delete(
  //       `https://dev.silzila.com/api/workspace/delete/${id}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       console.log("Workspace deleted successfully");
  //       mutate(); // Mutate the SWR cache to update workspaces
  //     } else {
  //       console.error("Failed to delete workspace");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting workspace:", error);
  //   }
  // };


  // const openEditModal = (id, currentName, currentParentId) => {
  //   setEditWorkspaceId(id);
  //   setWorkspaceName(currentName);
  //   setParentId(currentParentId);
  //   setIsEditModalOpen(true);
  // };

 
//   if (error) return <p>Error loading workspaces.</p>;

//   return (
//     <>
//       <Link to="/admin/users">
//         <button className={styles.backLink}>Back</button>
//       </Link>
//       {/* <div className={styles.actionButtons}>
//         <button
//           className={styles.createWorkspace}
//           onClick={() => setIsModalOpen(true)}
//         >
//           <img
//             className="addIcon addIconDefault"
//             src="/add.png"
//             alt="Add Users Logo"
//             width={20}
//             height={20}
//           />
//           Create Workspace
//         </button>
//       </div> */}
//       <div className={styles.workspaceList}>
//         {isLoading ? (
//           <p>Loading workspaces...</p>
//         ) : workspaces.length === 0 ? (
//           <p className={styles.noWorkspaces}>
//             Currently, there are no workspaces created.
//           </p>
//         ) : (
//           <table className={styles.workspaceTable}>
//             <thead>
//               <tr>
//                 <th></th>
//                 <th>Workspace</th>
//                 {/* <th className={styles.icons}><img src='/subworkspace.png' alt='' height={20} width={20} /></th>
//                 <th className={styles.icons}><img src='/database_header.png' alt='' height={20} width={20} /></th>
//                 <th className={styles.icons}><img src='/dataset_header.png' alt='' height={20} width={20} /></th>
//                 <th className={styles.icons}><img src='/flatfile_header.png' alt='' height={20} width={20} /></th>
//                 <th className={styles.icons}><img src='/playbook_header.png' alt='' height={20} width={20} /></th> */}
//                 <th>Modified at</th>
//                 <th>Modified by</th>
//                 {/* <th>Actions</th> */}
//               </tr>
//             </thead>
//             <tbody>
//               {workspaces.map((workspace) => (
//                 <tr
//                   key={workspace.id}
//                   onMouseEnter={() => setHoveredRowId(workspace.id)}
//                   onMouseLeave={() => setHoveredRowId(null)}
//                 >
//                   <td><img src='/open_folder.png' className={styles.icon} height={20} width={20}></img></td>
//                   <td>
//                     <Link to={`/subworkspace/${workspace.id}`}>
//                       {workspace.name}
//                     </Link>
//                   </td>
//                   {/* <td>{workspace.subWorkspaceCount}</td>
//                   <td>{workspace.dbConnectionCount}</td>
//                   <td>{workspace.datasetCount}</td>
//                   <td>{workspace.flatfileCount}</td>
//                   <td>{workspace.playbookCount}</td> */}
//                   <td>{workspace.createdAt}</td> 
//                   <td>{workspace.createdBy}</td>
              //     {/* <td>
              //       <img
              //         src={
              //           hoveredRowId === workspace.id
              //             ? "/edit.png"
              //             : "/edit_white.png"
              //         }
              //         alt="Edit"
              //         className={`${styles.actionIcon} ${styles.editIcon}`}
              //         onClick={(e) => {
              //           e.stopPropagation();
              //           openEditModal(
              //             workspace.id,
              //             workspace.workspaceName,
              //             workspace.parentId
              //           );
              //         }}
              //         height={20}
              //         width={20}
              //       />
              //       <img
              //         src={
              //           hoveredRowId === workspace.id
              //             ? "/delete.png"
              //             : "/delete_white.png"
              //         }
              //         alt="Delete"
              //         className={`${styles.actionIcon} ${styles.deleteIcon}`}
              //         onClick={(e) => {
              //           e.stopPropagation();
              //           handleDeleteWorkspace(workspace.id);
              //         }}
              //         height={20}
              //         width={20}
              //       />
              //     </td> */}
              //   </tr>
              // ))}

//             </tbody>
//           </table>
//         )}
//       </div>
      // {/* Create Workspace Modal */}
      // {isModalOpen && (
      //   <div className={styles.modal}>
      //     <div className={styles.modalContent}>
      //       <h2>Create Workspace</h2>
      //       <input
      //         type="text"
      //         value={workspaceName}
      //         onChange={(e) => setWorkspaceName(e.target.value)}
      //         placeholder="Workspace Name"
      //       />
      //       <button onClick={handleCreateWorkspace}>Create</button>
      //       <button onClick={() => setIsModalOpen(false)}>Cancel</button>
      //     </div>
      //   </div>
      // )}

      // {/* Edit Workspace Modal */}
      // {isEditModalOpen && (
      //   <div className={styles.modal}>
      //     <div className={styles.modalContent}>
      //       <h2>Edit Workspace</h2>
      //       <input
      //         type="text"
      //         value={workspaceName}
      //         onChange={(e) => setWorkspaceName(e.target.value)}
      //         placeholder="Workspace Name"
      //       />
      //       <button onClick={handleEditWorkspace}>Save</button>
      //       <button onClick={() => setIsEditModalOpen(false)}>Cancel</button>
      //     </div>
      //   </div>
      // )}

      
      
//     </>
//   );
// };

// export default WorkspaceList;