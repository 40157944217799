import axios from "axios";
import { serverEndPoint } from "./EnvironmentVariables";
import Logger from "../../Logger";


const FetchData = async (props:any) => {
	const { requestType, method, url, headers, data } = props;

	// if (token) {
	//     var token2 = await CheckTokenValidity(token);
	// }

	// if (token2) {
	//  -------- below promise code here --------
	// } else {
	// return { status: false, data: { detail: "Token Expired" } };
	// }

	return new Promise(resolve => {
		switch (requestType) {
			case "withData":
				axios({ method, url: serverEndPoint + url, headers, data, timeout:1000 * 10 })
					.then(res => resolve({ status: true, data: res.data }))
					.catch(err => {
						Logger("error", err);
						if (err?.response?.data) {
							resolve({ status: false, data: err.response.data });
						} else {
							resolve({ status: false, data: { detail: "Unknown error" } });
						}
					});
				break;

			case "noData":
				axios({ method, url: serverEndPoint + url, headers, timeout:1000 * 10 })
					.then(res => resolve({ status: true, data: res.data }))
					.catch(err => {
						Logger("error", err);
						if (err?.response?.data) {
							resolve({ status: false, data: err.response.data });
						} else {
							resolve({ status: false, data: { detail: "Unknown error" } });
						}
					});
				break;

			default:
				break;
		}
	});
};

export default FetchData;
