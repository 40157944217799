import React, {useEffect} from "react";
import { connect } from "react-redux";
import { HashRouter as Router, Route, Routes } from "react-router-dom"; // Import necessary components from react-router-dom
import { Navigate } from "react-router-dom"; // Import Navigate component
import Cookies from 'js-cookie';
import { Dispatch } from "redux";
import axios from 'axios';
import { userAuthentication } from "../redux/UserInfo/isLoggedActions";

import AddUser from '../pages/AddUser';
import AdminUser from '../pages/AdminUser';
import UserProf from '../pages/UserProf';
import Groups from '../pages/Groups';
import GroupDetails from '../pages/GroupDetails';
import Admins from '../pages/Admins';
import Workspace from '../pages/Workspace';
import SubWork from '../pages/SubWork';
import SubWorkDetails from '../pages/SubWorkDetails';
import AccessPage from '../pages/AccessPage';
import NewDataConnection from '../Components/DataConnection/NewDataConnection';
import {localEndPoint, serverEndPoint} from './ServerCall/EnvironmentVariables';

const Home = (props: any) => {

    useEffect(() => {
        const authToken = Cookies.get('authToken');
        console.log("Token:", authToken);
    
        if (!authToken) {        
          console.log("failed NO Token:", authToken);
          window.location.href = `${localEndPoint}auth/business/signin`;
        } else {   
          const axiosInstance = axios.create({
              headers: {
                  'Authorization': `Bearer ${authToken}`,
                  'X-TENANT-ID': 'silzila',
                  'Content-type': 'application/json'
              }
          });

          axiosInstance.get(`${serverEndPoint}checktoken`).then((response)=>{
            if(response){
              localStorage.setItem('accessToken', authToken);  
              console.log("accessToken:", authToken);

              let payload = {
                isUserLogged: true,
                accessToken: authToken,
                tokenType: "",
            };

              props.userAuthentication(payload);
            }
            else{
              console.log("Failed:", authToken);
              window.location.href = `${localEndPoint}auth/business/signin`;
            }
          }).catch((err)=>{
            console.log("Failed catch:", authToken);
            window.location.href = `${localEndPoint}auth/business/signin`;
          });
         
        }
      }, []);

    return (
        <Routes>
            {props.isUserLogged ? 
            <>
                <Route path="/" element={<Workspace/>}/>
                <Route path="/users/add" element={<AddUser />} />
                <Route path="/admin/users" element={<AdminUser />} />
                <Route path="/update-profile" element={<UserProf />} />
                <Route path="/admin/groups" element={<Groups/>}/>
                <Route path="/group-details/:groupId" element={<GroupDetails/>}/>
                <Route path="/admin/admins" element={<Admins/>}/>                
                <Route path='/workspace/:parentId' element={<SubWork/>}/>
                <Route path="/workspace/access/:parentId" element={<AccessPage/>}/>
                <Route path="/SubWorkspaceDetails/:parentId" element={<SubWorkDetails/>}/>
                <Route path="/newdataconnection/:parentId" element={<NewDataConnection />} />
            </> 
            : null}
        </Routes>
    );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isUserLogged: state.isLogged.isUserLogged,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		userAuthentication: (payload: any) => dispatch(userAuthentication(payload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);