"use client";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './users.modules.css';
import axios from 'axios';

const Users = () => {
    const [users, setUsers] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [searchQuery, setSearchQuery] = useState<any>('');
    const [filteredUsers, setFilteredUsers] = useState<any>([]);
    const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
    const [groupName, setGroupName] = useState<any>('');
    const [showCreateGroupDialog, setShowCreateGroupDialog] = useState<any>(false);
    const [sortConfig, setSortConfig] = useState<any>({ key: '', direction: 'asc' });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<any>(false);
    const [userIndexToDelete, setUserIndexToDelete] = useState<any>(null);
    const [showDeactivateConfirmation, setShowDeactivateConfirmation] = useState<any>(false);
    const [userIndexToDeactivate, setUserIndexToDeactivate] = useState<any>(null);
    const [showAlreadyDeactivatedDialog, setShowAlreadyDeactivatedDialog] = useState<any>(false);
    const [userToDelete, setUserToDelete] = useState<any>(null);
    const [userToDeactivate, setUserToDeactivate] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(true); 

    const accessToken = localStorage.getItem('accessToken');

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-TENANT-ID': 'silzila',
            'Content-type': 'application/json'
        }
    });
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get('https://dev.silzila.com/api/users/list');
                const usersWithProfileImages = await Promise.all(response.data.map(async (user:any) => {
                    try {
                        const imageResponse = await axiosInstance.get(`https://dev.silzila.com/api/user/profile-image`);
                        if (imageResponse.data) {
                            if(!user.profileImage){
                                return { ...user, profileImage: "/default.png" };
                            }
                            else{
                                const profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`
                                return { ...user, profileImage: profileImageUrl };
                            }
                        }
                         else {
                            console.warn(`No profile image found for user ID ${user.id}`);
                            return { ...user, profileImage: '/default.png' };
                        }
                    } catch (imageError) {
                        console.error('Error fetching user profile image:', imageError);
                        return { ...user, profileImage: '/default.png' };
                    }
                }));
                setUsers(usersWithProfileImages);
            } catch (error) {
                console.error('Error fetching users:', error);
                alert('Error fetching users. Please try again.');
            }
            finally {
                setIsLoading(false);  // Set loading to false after data fetching is complete
            }
        };
        fetchUsers();
    }, []);
    
    useEffect(() => {
        const filterUsers = () => {
            const lowerCaseQuery = searchQuery.toLowerCase();
            return users.filter((user:any) => {
                const firstName = user.firstName?.toLowerCase() || '';
                const email = user.email?.toLowerCase() || '';
                return firstName.includes(lowerCaseQuery) || email.includes(lowerCaseQuery);
            });
        };

        const sortedAndFilteredUsers = () => {
            const filtered = filterUsers();

            if (sortConfig.key) {
                return filtered.sort((a:any, b:any) => {
                    let aValue, bValue;
                    if (sortConfig.key === 'firstName') {
                        aValue = a.firstName.toLowerCase() || '';
                        bValue = b.firstName.toLowerCase() || '';

                    } else if (sortConfig.key === 'email') {
                        aValue = a.email.toLowerCase() || '';
                        bValue = b.email.toLowerCase() || '';
                    } else if (sortConfig.key === 'status') {
                        aValue = a.isDeactivated ? 1 : 0; 
                        bValue = b.isDeactivated ? 1 : 0; 
                    }
                      
                    if (aValue < bValue) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }
                    return 0;
                });
            }
            return filtered;
        };

        setFilteredUsers(sortedAndFilteredUsers());
    }, [searchQuery, users, sortConfig]);
    

    const deleteUser = (index:any) => {
        setUserIndexToDelete(index);
        setUserToDelete(users[index]);
        setShowDeleteConfirmation(true);
    };

    const confirmDeleteUser = async () => {
        try {
            const userToDelete = users[userIndexToDelete];
            if (!userToDelete.id) throw new Error("User ID is missing");

            await axiosInstance.delete(`https://dev.silzila.com/api/user/delete/${userToDelete.id}`);
            const updatedUsers = users.filter((_:any, i:any) => i !== userIndexToDelete);
            setUsers(updatedUsers);
        } catch (error) {
            console.error('There was an error deleting the user:', error);
            alert('Error deleting user. Please try again.');
        } finally {
            setShowDeleteConfirmation(false);
            setUserIndexToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
        setUserIndexToDelete(null);
    };

    const deactivateUser = (index:any) => {
        const userToDeactivate = users[index];
        if (!userToDeactivate.isDeactivated) {
            setUserIndexToDeactivate(index);
            setUserToDeactivate(userToDeactivate);
            setShowDeactivateConfirmation(true);
        } else {
            // alert('User is already deactivated.');
            setShowAlreadyDeactivatedDialog(true);
        }
    };

    const confirmDeactivateUser = async () => {
        try {
            const userToDeactivate = users[userIndexToDeactivate];
            if (!userToDeactivate.id) throw new Error("User ID is missing");

            await axiosInstance.put(`https://dev.silzila.com/api/user/deactivate?userId=${userToDeactivate.id}&deactivate=true`);
            const updatedUsers = [...users];
            updatedUsers[userIndexToDeactivate].isDeactivated = true;
            setUsers(updatedUsers);
        } catch (error) {
            console.error('There was an error deactivating the user:', error);
            alert('Error deactivating user. Please try again.');
        } finally {
            setShowDeactivateConfirmation(false);
            setUserIndexToDeactivate(null);
        }
    };

    const cancelDeactivate = () => {
        setShowDeactivateConfirmation(false);
        setUserIndexToDeactivate(null);
    };

    const closeAlreadyDeactivatedDialog = () => {
        setShowAlreadyDeactivatedDialog(false);
    };

    const toggleSelectUser = (index:any) => {
        if (selectedUsers.includes(index)) {
            setSelectedUsers(selectedUsers.filter((i:any) => i !== index));
        } else {
            setSelectedUsers([...selectedUsers, index]);
        }
    };

    const handleMouseEnter = (index:any) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    const handleCreateGroup = () => {
        setShowCreateGroupDialog(true);
    };

    const handleGroupNameChange = (e:any) => {
        setGroupName(e.target.value);
    };

    const handleCloseDialog = () => {
        setShowCreateGroupDialog(false);
        setGroupName('');
    };

    const handleCreateGroupConfirm = async () => {
        const userIds = selectedUsers.map((index:any) => users[index].id);
        const requestBody = {
            userIds: userIds,
            groupName: groupName
        };

        try {
            await axiosInstance.post('https://dev.silzila.com/api/group-users/create', requestBody);
            console.log(`Group "${groupName}" created successfully with users:`, userIds);
            setShowCreateGroupDialog(false);
            setGroupName('');
            setSelectedUsers([]);
        } catch (error) {
            console.error('Error creating group:', error);
            alert('Error creating group. Please try again.');
        }
    };

    const handleSort = (key:any) => {
        const newDirection = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction: newDirection });
    };

    const getSortIcon = (key:any) => {
        if (sortConfig.key === key) {
            if (key === 'status') {
                return sortConfig.direction === 'asc' ? (
                    <img className='headerIcon' src="/status_activated.png" alt="Status Ascending" width={25} height={25} />
                ) : (
                    <img className='headerIcon' src="/status_deactivated.png" alt="Status Descending" width={25} height={25} />
                );
            } else {  
                return sortConfig.direction === 'asc' ? (
                    <img className='headerIcon'src="/sort-up.png" alt="Ascending" width={25} height={25} />
                ) : (
                    <img className='headerIcon'src="/sort-down.png" alt="Descending" width={25} height={25} />
                );
            }
        }
        return <img className='headerIcon' src="/sort.png" alt="Default" width={25} height={25} />;
    };


    return (
        <>
            <div className="container">
                <div className="buttonContainer">
                    <div className="searchContainer">
                        <input
                            type="text"
                            placeholder="Search users"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="searchInput"
                        />
                        <img src="/glass.png" alt="Search Icon" className="searchIcon" width={20} height={20} />
                    </div>
                </div>

                <div className="actionButtons">
                    {selectedUsers.length === 0 ? (
                        <Link to="/users/add" className="linkButton">
                            <button className="button">
                                {/* <img className='addIcon'src="/add_white.png" alt="Add Users Logo" width={20} height={20} /> */}
                                <img className='addIcon addIconDefault' src="/add_green.png" alt="Add Users Logo" width={20} height={20} />
                                <img className='addIcon addIconHover' src="/add_white.png" alt="Add Users Logo Hover" width={20} height={20} />
                                Add Users
                            </button>
                        </Link>
                    ) : (
                        <button
                            className="button"
                            onClick={handleCreateGroup}
                            // style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                        >
                            <img className='addIcon addIconDefault'src='/groups_green.png' alt="Create Group" height={20} width={20} />
                            <img className='addIcon addIconHover'src='/groups_white.png' alt="Create Group" height={20} width={20} />
                             Create Group
                        </button>
                    )}
                </div>
                {isLoading ? (  
                    <div className="loading-container">
                        <div className="spinner"></div>
                    </div>
                ) : users.length === 0 ? (
                <p className='default-text'>
                    You have not added any users in your organisation. Click here to <Link className="link" to="/add-users">Add Users</Link>
                </p>
            ) : (
                // {users.length === 0 ? (
                //     <p className='default-text'>You have not added any users in your organisation. Click here to <Link className="link" to="/add-users">Add Users</Link></p>
                // ) : (
                    <div className='tableContainer'>
                    <table className="userTable">
                        <thead>
                            <tr>
                                <th style={{width:'60px',textAlign:'center',alignItems:'center'}}></th>
                                <th style={{width:'60px',textAlign:'center',alignItems:'center'}}></th>
                                <th style={{ width: '180px' }} onClick={() => handleSort('firstName')}>
                                        Name &nbsp;&nbsp;&nbsp;&nbsp;{getSortIcon('firstName')}
                                </th>
                                <th style={{ width: '200px' }} onClick={() => handleSort('email')}>
                                        Email &nbsp;&nbsp;{getSortIcon('email')}
                                </th>
                                <th style={{width:'100px',paddingLeft:'80px'}} onClick={() => handleSort('status')} >
                                    Status &nbsp;&nbsp;{getSortIcon('status')}
                                </th>
                                <th style={{width:'120px'}}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user:any, index:any) => (
                                <tr
                                    key={index}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    className={index === hoveredIndex ? 'hoveredRow' : 'userRow'}
                                >
                                    <td style={{width:'50px',paddingLeft:'70px'}}>
                                        <input
                                            type="checkbox"
                                            checked={selectedUsers.includes(index)}
                                            onChange={() => toggleSelectUser(index)}
                                        />
                                    </td>
                                    <td style={{width:'50px',paddingLeft:'60px'}}>
                                        <img src={user.profileImage ? user.profileImage: '/default.png'} alt={`${user.firstName}`} width={30} height={30} />
                                    </td>
                                    <td>{user.firstName}</td>
                                    <td>{user.email}</td>
                                    <td style={{paddingLeft:'80px'}}>
                                    <div className="Tooltip">
                                        <img
                                            className='Tooltip'
                                            src={user.isDeactivated ? '/cross.png' : '/yes.png'}
                                            alt={user.isDeactivated ? 'Deactivated' : 'Active'}
                                            width={20}
                                            height={20}
                                        />
                                        <span className="tooltip-text">{user.isDeactivated ?'Deactivated User': 'Active User'}</span>
                                        </div>
                                    </td>
                                    <td className="action-container-users">
                                    <div className="Tooltip">
                                        <button 
                                            className='Tooltip'
                                            onClick={() => deleteUser(index)}
                                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                        >
                                            <img
                                                src={hoveredIndex === index ? '/delete_red.png ' : '/delete_white.png'}
                                                alt="Delete User"
                                                height={20}
                                                width={20}
                                            />
                                        </button>
                                        <span className="tooltip-text">Delete User</span>
                                        </div> 

                                        &nbsp; &nbsp;
                                        <div className="Tooltip">
                                        <button 
                                           className='Tooltip'
                                            onClick={() => deactivateUser(index)}
                                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                        >
                                            <img
                                                src={hoveredIndex === index ? '/deactivate_orange.png' : '/deactivate_white.png'}
                                                alt="Deactivate User"
                                                height={20}
                                                width={20}
                                            />
                                        </button>
                                        <span className="tooltip-text">Deactivate User</span>
                                        </div> 
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                )}
                {showCreateGroupDialog && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseDialog}>&times;</span>
                            <p>Please name the group:</p>
                            <input type="text" required value={groupName} onChange={handleGroupNameChange} />
                            <button  onClick={handleCreateGroupConfirm}>Create Group</button>
                        </div>
                    </div>
                )}

                {showDeleteConfirmation && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={cancelDelete}>&times;</span>
                            <p>Are you sure you want to delete {userToDelete?.firstName}?</p>
                            <div className="modal-buttons">
                                <button onClick={cancelDelete} className="modal-cancel">Cancel</button>
                                <button onClick={confirmDeleteUser} className="modal-confirm">Confirm</button>
                            </div>
                         </div>
                    </div>
                )}

                {showDeactivateConfirmation && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={cancelDeactivate}>&times;</span>
                            <p>Are you sure you want to deactivate {userToDeactivate?.firstName}?</p>
                            <div className="modal-buttons">
                                <button onClick={cancelDeactivate} className="modal-cancel">Cancel</button>
                                <button onClick={confirmDeactivateUser} className="modal-confirm-deactivate">Confirm</button>
                            </div>
                         </div>
                    </div>
                )}

               {showAlreadyDeactivatedDialog && (
                <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeAlreadyDeactivatedDialog}>&times;</span>
                        <p>This user is already deactivated.</p>
                    </div>
                </div>
               )}
            </div>
        </>
    );
};

export default Users;




// // "use client";
// // import React, { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// // 
// // import axios from "axios";
// // import styles from './users.modules.css';

// const Users = () => {
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [hoveredIndex, setHoveredIndex] = useState(-1);
//   const [groupName, setGroupName] = useState("");
//   const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);
//   const [tooltip, setTooltip] = useState({ text: "", visible: false });
//   const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

//   // State for delete confirmation
//   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
//   const [userIndexToDelete, setUserIndexToDelete] = useState(null);

//   //State for Deactivate confirmation
//   const [showDeactivateConfirmation, setShowDeactivateConfirmation] = useState(false);
//   const [userIndexToDeactivate, setUserIndexToDeactivate] = useState(null);
//   const [showAlreadyDeactivatedDialog, setShowAlreadyDeactivatedDialog] = useState(false);

//   const accessToken = localStorage.getItem("accessToken");

//   const axiosInstance = axios.create({
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//       "X-TENANT-ID": "silzila",
//       "Content-type": "application/json",
//     },
//   });

//   const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);

//   const { data: users = [], error, isLoading } = useSWR(
//     "https://dev.silzila.com/api/users/list",
//     fetcher
//   );

//   useEffect(() => {
//     const filterUsers = () => {
//       const lowerCaseQuery = searchQuery.toLowerCase();
//       return users.filter((user) => {
//         const firstName = user.firstName?.toLowerCase() || "";
//         const email = user.email?.toLowerCase() || "";
//         return firstName.includes(lowerCaseQuery) || email.includes(lowerCaseQuery);
//       });
//     };

//     const sortedAndFilteredUsers = () => {
//       const filtered = filterUsers();

//       if (sortConfig.key) {
//         return filtered.sort((a, b) => {
//           let aValue, bValue;
//           if (sortConfig.key === "firstName") {
//             aValue = a.firstName.toLowerCase() || "";
//             bValue = b.firstName.toLowerCase() || "";
//           } else if (sortConfig.key === "email") {
//             aValue = a.email.toLowerCase() || "";
//             bValue = b.email.toLowerCase() || "";
//           } else if (sortConfig.key === "status") {
//             aValue = a.isDeactivated ? 1 : 0;
//             bValue = b.isDeactivated ? 1 : 0;
//           }

//           if (aValue < bValue) {
//             return sortConfig.direction === "asc" ? -1 : 1;
//           }
//           if (aValue > bValue) {
//             return sortConfig.direction === "asc" ? 1 : -1;
//           }
//           return 0;
//         });
//       }
//       return filtered;
//     };

//     setFilteredUsers(sortedAndFilteredUsers());
//   }, [searchQuery, users, sortConfig]);

//   const deleteUser = (index) => {
//     setUserIndexToDelete(index);
//     setShowDeleteConfirmation(true);
//   };

//   const confirmDeleteUser = async () => {
//     try {
//       const userToDelete = users[userIndexToDelete];
//       if (!userToDelete.id) throw new Error("User ID is missing");
  
//       await axiosInstance.delete(`https://dev.silzila.com/api/user/delete/${userToDelete.id}`);
      
//       // Update SWR cache
//       mutate("https://dev.silzila.com/api/users/list", users.filter((_, i) => i !== userIndexToDelete), false);
//     } catch (error) {
//       console.error("There was an error deleting the user:", error);
//       alert("Error deleting user. Please try again.");
//     } finally {
//       setShowDeleteConfirmation(false);
//       setUserIndexToDelete(null);
//     }
//   };

//   const cancelDelete = () => {
//     setShowDeleteConfirmation(false);
//     setUserIndexToDelete(null);
//   };

//   const deactivateUser = (index) => {
//     const userToDeactivate = users[index];
//     if (!userToDeactivate.isDeactivated) {
//       setUserIndexToDeactivate(index);
//       setShowDeactivateConfirmation(true);
//     } else {
//       setShowAlreadyDeactivatedDialog(true);
//     }
//   };

//   const confirmDeactivateUser = async () => {
//     try {
//       const userToDeactivate = users[userIndexToDeactivate];
//       if (!userToDeactivate.id) throw new Error("User ID is missing");
  
//       await axiosInstance.put(
//         `https://dev.silzila.com/api/user/deactivate?userId=${userToDeactivate.id}&deactivate=true`
//       );
  
//       // Update SWR cache
//       const updatedUsers = users.map((user, i) => {
//         if (i === userIndexToDeactivate) {
//           return { ...user, isDeactivated: true };
//         }
//         return user;
//       });
      
//       mutate("https://dev.silzila.com/api/users/list", updatedUsers, false);
//     } catch (error) {
//       console.error("There was an error deactivating the user:", error);
//       alert("Error deactivating user. Please try again.");
//     } finally {
//       setShowDeactivateConfirmation(false);
//       setUserIndexToDeactivate(null);
//     }
//   };

//   const cancelDeactivate = () => {
//     setShowDeactivateConfirmation(false);
//     setUserIndexToDeactivate(null);
//   };

//   const closeAlreadyDeactivatedDialog = () => {
//     setShowAlreadyDeactivatedDialog(false);
//   };

//   const toggleSelectUser = (index) => {
//     if (selectedUsers.includes(index)) {
//       setSelectedUsers(selectedUsers.filter((i) => i !== index));
//     } else {
//       setSelectedUsers([...selectedUsers, index]);
//     }
//   };

//   const handleMouseEnter = (index) => {
//     setHoveredIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setHoveredIndex(-1);
//   };

//   const handleCreateGroup = () => {
//     setShowCreateGroupDialog(true);
//   };

//   const handleGroupNameChange = (e) => {
//     setGroupName(e.target.value);
//   };

//   const handleCloseDialog = () => {
//     setShowCreateGroupDialog(false);
//     setGroupName("");
//   };

//   const handleCreateGroupConfirm = async () => {
//     const userIds = selectedUsers.map((index) => users[index].id);
//     const requestBody = {
//       userIds: userIds,
//       groupName: groupName,
//     };

//     try {
//       await axiosInstance.post("https://dev.silzila.com/api/group-users/create", requestBody);
//       console.log(`Group "${groupName}" created successfully with users:`, userIds);
//       setShowCreateGroupDialog(false);
//       setGroupName("");
//       setSelectedUsers([]);
//     } catch (error) {
//       console.error("Error creating group:", error);
//       alert("Error creating group. Please try again.");
//     }
//   };

//   const showTooltip = (text) => {
//     setTooltip({ text, visible: true });
//   };

//   const hideTooltip = () => {
//     setTooltip({ text: "", visible: false });
//   };

//   const handleSort = (key) => {
//     const newDirection = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
//     setSortConfig({ key, direction: newDirection });
//   };

//   const getSortIcon = (key) => {
//     if (sortConfig.key === key) {
//       if (key === "status") {
//         return sortConfig.direction === "asc" ? (
//           <img
//             className="headerIcon"
//             src="/status_activated.png"
//             alt="Status Ascending"
//             width={25}
//             height={25}
//           />
//         ) : (
//           <img
//             className="headerIcon"
//             src="/status_deactivated.png"
//             alt="Status Descending"
//             width={25}
//             height={25}
//           />
//         );
//       } else {
//         return sortConfig.direction === "asc" ? (
//           <img className="headerIcon" src="/ascending.png" alt="Ascending" width={25} height={25} />
//         ) : (
//           <img
//             className="headerIcon"
//             src="/descending.png"
//             alt="Descending"
//             width={25}
//             height={25}
//           />
//         );
//       }
//     }
//     return <img className="headerIcon" src="/default_sort.png" alt="Default" width={25} height={25} />;
//   };

//   if (isLoading) return <div>Loading...</div>;
//   if (error) return <div>Error loading users: {error.message}</div>;

//   return (
//     <>
//       <div className="container">
//         <div className="buttonContainer">
//           <div className="searchContainer">
//             <input
//               type="text"
//               placeholder="Search users"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//             <Link to="/group-details">
//               <button
//                 onMouseEnter={() => showTooltip("Go to group details")}
//                 onMouseLeave={hideTooltip}
//               >
//                 Go to Group Details
//               </button>
//             </Link>
//           </div>
//           <div>
//             <button
//               onClick={handleCreateGroup}
//               onMouseEnter={() => showTooltip("Create new group")}
//               onMouseLeave={hideTooltip}
//               disabled={selectedUsers.length === 0}
//             >
//               Create Group
//             </button>
//           </div>
//           <div className="tooltip">{tooltip.visible && <span>{tooltip.text}</span>}</div>
//         </div>

//         <div className="user-list-container">
//           <div className="header">
//             <span onClick={() => handleSort("firstName")}>Name {getSortIcon("firstName")}</span>
//             <span onClick={() => handleSort("email")}>Email {getSortIcon("email")}</span>
//             <span onClick={() => handleSort("status")}>Status {getSortIcon("status")}</span>
//             <span>Action</span>
//           </div>
//           <div className="user-list">
//             {filteredUsers.length === 0 ? (
//               <div>No users found.</div>
//             ) : (
//               filteredUsers.map((user, index) => (
//                 <div
//                   key={index}
//                   className={`user-item ${
//                     selectedUsers.includes(index) ? "selected" : ""
//                   } ${hoveredIndex === index ? "hovered" : ""}`}
//                   onMouseEnter={() => handleMouseEnter(index)}
//                   onMouseLeave={handleMouseLeave}
//                 >
//                   <div className="user-info" onClick={() => toggleSelectUser(index)}>
//                     <div className="checkbox">
//                       <input type="checkbox" checked={selectedUsers.includes(index)} readOnly />
//                     </div>
//                     <div className="user-name">
//                       <span>{user.firstName}</span>
//                     </div>
//                     <div className="user-email">
//                       <span>{user.email}</span>
//                     </div>
//                     <div className="user-status">
//                       <span>{user.isDeactivated ? "Deactivated" : "Activated"}</span>
//                     </div>
//                   </div>
//                   <div className="user-actions">
//                     <button
//                       onClick={() => deleteUser(index)}
//                       onMouseEnter={() => showTooltip("Delete user")}
//                       onMouseLeave={hideTooltip}
//                     >
//                       <img
//                         className="action-icon"
//                         src="/delete.png"
//                         alt="Delete User"
//                         width={20}
//                         height={20}
//                       />
//                     </button>
//                     <button
//                       onClick={() => deactivateUser(index)}
//                       onMouseEnter={() => showTooltip("Deactivate user")}
//                       onMouseLeave={hideTooltip}
//                     >
//                       <img
//                         className="action-icon"
//                         src={user.isDeactivated ? "/deactivated.png" : "/deactivate.png"}
//                         alt={user.isDeactivated ? "User Deactivated" : "Deactivate User"}
//                         width={20}
//                         height={20}
//                       />
//                     </button>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>

//       {showCreateGroupDialog && (
//         <div className="dialog">
//           <div className="dialog-content">
//             <h2>Create Group</h2>
//             <label>
//               Group Name:
//               <input type="text" value={groupName} onChange={handleGroupNameChange} />
//             </label>
//             <div className="dialog-buttons">
//               <button onClick={handleCreateGroupConfirm}>Create</button>
//               <button onClick={handleCloseDialog}>Cancel</button>
//             </div>
//           </div>
//         </div>
//       )}

//       {showDeleteConfirmation && (
//         <div className="confirmation-dialog">
//           <div className="confirmation-dialog-content">
//             <p>Are you sure you want to delete this user?</p>
//             <div className="confirmation-dialog-buttons">
//               <button onClick={confirmDeleteUser}>Yes</button>
//               <button onClick={cancelDelete}>No</button>
//             </div>
//           </div>
//         </div>
//       )}

//       {showDeactivateConfirmation && (
//         <div className="confirmation-dialog">
//           <div className="confirmation-dialog-content">
//             <p>Are you sure you want to deactivate this user?</p>
//             <div className="confirmation-dialog-buttons">
//               <button onClick={confirmDeactivateUser}>Yes</button>
//               <button onClick={cancelDeactivate}>No</button>
//             </div>
//           </div>
//         </div>
//       )}

//       {showAlreadyDeactivatedDialog && (
//         <div className="dialog">
//           <div className="dialog-content">
//             <p>User is already deactivated.</p>
//             <button onClick={closeAlreadyDeactivatedDialog}>Close</button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Users;

