"use client";
import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import './groupDetail.css';


const GroupDetailsComponent = () => {
    const { groupId } = useParams<string>();
    const [participants, setParticipants] = useState<any>([]);
    const [allUsers, setAllUsers] = useState<any>([]);
    const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [searchQuery, setSearchQuery] = useState<any>('');
    const [showAddUsersDialog, setShowAddUsersDialog] = useState<any>(false);
    const [modalSelectedUsers, setModalSelectedUsers] = useState<any>([]);
    const [userIndexToDelete, setUserIndexToDelete] = useState<any>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<any>(false);
    const [userToRemove, setUserToRemove] = useState<any>(null);
    const [isLoading,setIsLoading]=useState<any>(true)

    const location = useLocation();
    const group = location.state?.groupName;
    
    // const fetchParticipants = () => {
    //     if (groupId) {
    //         const token = localStorage.getItem('accessToken');
    //         fetch(`https://dev.silzila.com/api/groups/list/${encodeURIComponent(groupId)}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'X-TENANT-ID': 'silzila',
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (Array.isArray(data)) {
    //                     setParticipants(data);
    //                     console.log(data);
    //                 } else {
    //                     console.error(`Group with ID "${groupId}" not found or has no participants.`);
    //                     setParticipants([]);
    //                 }
    //             })
    //             .catch(error => console.error('Error fetching group participants:', error));
    //     }
    // };

    // useEffect(() => {
    //     fetchParticipants();
    // }, [groupId]);

    const fetchParticipants = async () => {
        if (groupId) {
            const token = localStorage.getItem('accessToken');
            try {
                const response = await fetch(`https://dev.silzila.com/api/groups/list/${encodeURIComponent(groupId)}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-TENANT-ID': 'silzila',
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch participants');
                }
    
                const data = await response.json();
                if (Array.isArray(data)) {
                    setParticipants(data);
                } else {
                    console.error(`Group with ID "${groupId}" not found or has no participants.`);
                    setParticipants([]);
                }
            } catch (error) {
                console.error('Error fetching group participants:', error);
            } finally {
                setIsLoading(false);  // Ensure the spinner stops after fetching
            }
        }
    };
    
    useEffect(() => {
        fetchParticipants();
    }, [groupId]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        fetch('https://dev.silzila.com/api/users/list', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-TENANT-ID': 'silzila',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setAllUsers(data);
                } else {
                    setAllUsers([]);
                    console.error('Error fetching users: Response is not an array.');
                }
            })
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    const handleMouseEnter = (index:any) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    const toggleSelectUser = (index:any) => {
        setSelectedUsers((prevSelectedUsers:any) =>
            prevSelectedUsers.includes(index)
                ? prevSelectedUsers.filter((i:any) => i !== index)
                : [...prevSelectedUsers, index]
        );
    };

    const toggleModalSelectUser = (index:any) => {
        setModalSelectedUsers((prevModalSelectedUsers:any) =>
            prevModalSelectedUsers.includes(index)
                ? prevModalSelectedUsers.filter((i:any) => i !== index)
                : [...prevModalSelectedUsers, index]
        );
    };

    const handleAddUsers = () => {
        setShowAddUsersDialog(true);
    };


    const handleAddUsersConfirm = () => {
        const userIds = modalSelectedUsers.map((index:any) => allUsers[index].id);
        const token = localStorage.getItem('accessToken');
        fetch(`https://dev.silzila.com/api/groups/adduser`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-TENANT-ID': 'silzila',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userIds, groupId: parseInt(groupId as string) }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    fetchParticipants(); // Fetch updated participants list
                    setShowAddUsersDialog(false);
                    setModalSelectedUsers([]);
                } else {
                    console.error('Error adding users to group:', data.message);
                }
            })
            .catch(error => console.error('Error adding users to group:', error));
    };

    const deleteUser = (index:any) => {
        setUserIndexToDelete(index);
        setUserToRemove(participants[index])
        setShowDeleteConfirmation(true);
    };

    const confirmDeleteUser = async () => {
        const userIndex = userIndexToDelete;
        if (userIndex !== null) {
            const userId = participants[userIndex].id;
            const token = localStorage.getItem('accessToken');
            const url = `https://dev.silzila.com/api/groups/removeuser`;
            const payload = {
                userIds: [userId],
                groupId: parseInt(groupId as string),
            };
    
            try {
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-TENANT-ID': 'silzila',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
    
                const data = await response.json();
    
                if (data.success) {
                    const updatedParticipants = participants.filter((_:any, i:any) => i !== userIndex);
                    setParticipants(updatedParticipants);
                } else {
                    console.error('Error deleting user from group:', data.message);
                }
            } catch (error) {
                console.error('Error deleting user from group:', error);
            } finally {
                setShowDeleteConfirmation(false);
                setUserIndexToDelete(null);
            }
        }
    };
    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
        setUserIndexToDelete(null);
    };


    const handleSearchChange = (e:any) => {
        setSearchQuery(e.target.value);
    };

    const filteredAllUsers = Array.isArray(allUsers) ? allUsers.filter(
        (user) =>
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];

    const isUserInGroup = (user:any) => {
        return participants.some((participant:any) => participant.email === user.email);
    };

    return (
        <div className="container">
            <Link to='/admin/groups'><button className='back-link-groupdetails'>Back</button></Link>
            {/* <h2 className="header">{groupId}</h2> */}
            <div className="buttonContainer">
                <div className="searchContainer">
                    <input
                        type="text"
                        placeholder="Search users"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="searchInput"
                    />
                    <img src="/glass.png" alt="Search Icon" className="searchIcon" width={20} height={20} />
                 </div>
            </div>

            <div className="actionButtons">
                <button className="button user" onClick={handleAddUsers}>
                <img className='addIcon addIconDefault' src="/add_green.png" alt="Add Users Logo" width={20} height={20} />
                <img className='addIcon addIconHover' src="/add_white.png" alt="Add Users Logo Hover" width={20} height={20} />
                Add Users
                </button>
                
                <div className="groupNameHeader">
                <p>{group}</p>  
               </div>

            </div>
            {isLoading ? (  
                <div className="loading-container">
                    <div className="spinner"></div> 
                </div>
                ) : participants.length === 0 ? (
                    <p style={{textAlign:'center',alignItems:'center',color:'#616161',marginTop:'130px'}}>No participants found for this group.</p>
                ) : (
            // {participants.length === 0 ? (
            //     <p style={{textAlign:'center',alignItems:'center',color:'#616161',marginTop:'130px'}}>No participants found for this group.</p>
            // ) : (
                <table className="userTable">
                    <thead>
                        <tr>
                          
                            <th style={{width:'80px'}}></th>
                            <th style={{width:'200px'}}>Name</th>
                            <th style={{width:'200px'}}>Email</th>
                            <th style={{width:'120px'}} >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {participants.map((participant:any, index:any) => (
                            <tr
                                key={index}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                className={index === hoveredIndex ? 'hoveredRow' : 'userRow'}
                            >
                                {/* <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.includes(index)}
                                        onChange={() => toggleSelectUser(index)}
                                    />
                                </td> */}
                                <td style={{paddingLeft:'100px'}}> 
                                    <img src={participant.picture ? participant.picture: '/default.png'} width={30} height={30} />
                                </td>
                                <td>{participant.firstName} {participant.lastName}</td>
                                <td>{participant.email}</td>
                                <td className="action-container">
                                {parseInt(groupId as string) !== 1 && (
                                    <>
                                    <div className="Tooltip">
                                    <button
                                         className='Tooltip'
                                        onClick={() => deleteUser(index)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                    >
                                        <img src={hoveredIndex === index ? '/cross_red.png' : '/cross_white.png'} alt="Delete User" height={20} width={20} />
                                    </button>
                                    <span className="tooltip-text">Remove User</span>
                                     </div>
                                    </>
                                    )}  
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {showAddUsersDialog && (
                <div className="modal" >
                    <div className="modal-content" style={{maxWidth:'30%'}}>
                        <span className="close" onClick={() => setShowAddUsersDialog(false)}>&times;</span>
                        <p style={{color:'#616164',fontSize:'16px'}}>Select users to add to the group:</p><br/>
                        <div className="userList">
                            {filteredAllUsers.map((user, index) => (
                                <div key={index} className="userItem" style={{color:'#616164'}}>
                                    <input
                                        type="checkbox"
                                        checked={modalSelectedUsers.includes(index)}
                                        onChange={() => toggleModalSelectUser(index)}
                                        disabled={isUserInGroup(user)}
                                    />
                                    <img src="/default-picture.png" alt={user.firstName} width={30} height={30} />
                                    <span style={{ color: isUserInGroup(user) ? '#eeeee1' : 'inherit' }}>
                                        {user.firstName} {user.lastName} &nbsp; ({user.email})
                                    </span>
                                </div>
                            ))}
                        </div>
                        <button style={{marginLeft:'170px'}}onClick={handleAddUsersConfirm}>Add Users</button>
                    </div>
                </div>
            )}
            {showDeleteConfirmation && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={cancelDelete}>&times;</span>
                            <p>Are you sure you want to remove {userToRemove.firstName}?</p>
                            <div className="modal-buttons">
                                <button onClick={cancelDelete} className="modal-cancel">Cancel</button>
                                <button onClick={confirmDeleteUser} className="modal-confirm">Confirm</button>
                            </div>
                         </div>
                    </div>
                )}
        </div>
 
    );
}

export default GroupDetailsComponent;



