import React from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import {
	Menu,
	Autocomplete,
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
  } from "@mui/material";
  import { CloseOutlined } from "@mui/icons-material";

  import {
    ffDialogTitle,
    ffButtonStyle,
  } from "./muiStyles";

const RichTreeViewControl = ({
    list, 
    showInPopup,
    handleCloseButtonClick,
    handleProceedButtonClick
}: any) =>{

    const [lastSelectedItem, setLastSelectedItem] = React.useState<string | null>(null);
    
    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
      ) => {
        if (isSelected) {
          setLastSelectedItem(itemId);
        }
      };

    return(
        showInPopup ?         
        <Dialog
        id="basic-menu"
        className="geoHelpTable"
        open={showInPopup}
        PaperProps={{
          sx: {
            minHeight: "20%",
          },
        }}>
        <DialogTitle sx={{ ...ffDialogTitle, background: "#8eedef" }}>
        <div>
          <b>Workspace</b>
        </div>

        <CloseOutlined
          onClick={handleCloseButtonClick}
          style={{ float: "right" }}
        />
      </DialogTitle>
      <DialogContent sx={{ height: "25rem", overflowY: "auto" }}>
        <RichTreeView items={list} onItemSelectionToggle={handleItemSelectionToggle}/> 
      </DialogContent>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          fontSize: "16px",
          columnGap: "1rem",
          paddingRight: "1rem",
          paddingBottom: "1rem",
          paddingTop: "1rem",
        }}
      >
        <Button
          onClick={handleCloseButtonClick}
          sx={{
            ...ffButtonStyle,
            border: "2px solid grey",
            color: "grey",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e)=>handleProceedButtonClick(lastSelectedItem)}
          sx={{
            ...ffButtonStyle,
            backgroundColor: "#2bb9bb",
            border: "2px solid #2bb9bb",
            color: "black",
          }}
        >
          Proceed
        </Button>
      </div>

        </Dialog>           
        :  <RichTreeView items={list} /> 
       
    )
}

export default RichTreeViewControl;