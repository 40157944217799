"use client";
import React from 'react';
import Header from '../Components/Header';
import Navbar from '../Components/Navbar';
import GroupDetailsComponent from '../Components/group-details';

const GroupDetails = () => {
    return (
        <>
            <Header />
            <Navbar />
            <GroupDetailsComponent />
        </>
    );
}

export default GroupDetails;
