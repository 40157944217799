import React from 'react';
import Header from '../Components/Header';
import AddUser from '../Components/AddUser';
import Navbar from '../Components/Navbar';

const AddUsers = () => {
  return (
    <>
      <Header />
      <Navbar />
      <AddUser />
    </>
  );
}

export default AddUsers;
