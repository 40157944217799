import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useSWR from "swr";
import styles from "./SubWorkspaceDetails.module.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";


const fetcher = (url:any) =>
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((res) => res.data);

const SubWorkspace = () => {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [workspaceName, setWorkspaceName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [editWorkspaceId, setEditWorkspaceId] = useState(null);
  const [modalType, setModalType] = useState("");
  const { parentId } = useParams();
  const [parentWorkspaceName, setParentWorkspaceName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const navigate = useNavigate();
  const[isLoading,setIsLoading]=useState(true);
  const id_parent=null;
  const [mode] = useState("New");
   // State for delete confirmation
   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
   const [IndexToDelete, setIndexToDelete] = useState(null);

  const { data: subWorkspaces, error, mutate } = useSWR(
    
    parentId
      ? `https://dev.silzila.com/api/workspace/${parentId}`
      : null,
    fetcher
  );

  const handleSubWorkspaceClick = (swid:any, swname:any) => {
    navigate(`/SubWorkspaceDetails/${swid}`, { state: { swname } });
    
    localStorage.setItem('pid',parentId as string)
};

const deleteWorkspace = (index:any,name:any) => {
  setIndexToDelete(index);
  setWorkspaceName(name);
  setShowDeleteConfirmation(true);
};

const handleDeleteWorkspace = async () => {
  try {
    const response = await axios.delete(
      `https://dev.silzila.com/api/workspace/delete/${IndexToDelete}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.status === 200) {
      console.log("Workspace deleted successfully");
      mutate(); 
    } else {
      console.error("Failed to delete workspace");
    }
  } catch (error) {
    console.error("Error deleting workspace:", error);
  }
  finally {
    setShowDeleteConfirmation(false);
    setIndexToDelete(null);
}
};

const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setIndexToDelete(null);
};

const handleEditWorkspace = async () => {
  try {
    const response = await axios.put(
      "https://dev.silzila.com/api/workspace/update",
      {
        workspaceId: editWorkspaceId,
        name: workspaceName,
        parentId: parentId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.status === 200) {
      console.log("Workspace updated successfully");
      mutate(); 
      setIsEditModalOpen(false); 
      setWorkspaceName(""); 
      setEditWorkspaceId(null); 
    } else {
      console.error("Failed to update workspace");
    }
  } catch (error) {
    console.error("Error updating workspace:", error);
  }
};

const openEditModal = (id:any, currentName:any, currentParentId:any) => {
  setEditWorkspaceId(id);
  setWorkspaceName(currentName);
  // setParentId(currentParentId);
  setIsEditModalOpen(true);
};

  useEffect(() => {
    const fetchParentWorkspaceName = async () => {
      if (parentId) {
        try {
          const response = await axios.get(
            `https://dev.silzila.com/api/workspace`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          const foundWorkspace = response.data.find(
            (workspace:any) => workspace.id === parentId
          );

        
          if (foundWorkspace) {
            setParentWorkspaceName(foundWorkspace.name);
            setOwnerName(foundWorkspace.createdBy);
          } else {
            console.error("Workspace not found");
          }
        } catch (error) {
          console.error("Error fetching workspace list:", error);
        }
        finally{
          setIsLoading(false);
        }
      }
    };

    fetchParentWorkspaceName();
  }, [parentId]);

  const handleCreateItem = async () => {
    let url;
    let data;

    switch (modalType) {
      case "Workspace":
        url = "https://dev.silzila.com/api/sub-workspace/create";
        data = { name: workspaceName, parentId: parentId || null };
        break;
      case "Flatfile":
      case "Playbook":
      case "DB Connections":
      case "Dataset":
        url = "https://dev.silzila.com/api/content/create";
        data = { name: workspaceName };
        break;
      default:
        return;
    }

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (response.status === 200) {
        console.log(`${modalType} created successfully`);
        mutate(); // Revalidate the data after creating a new item
        setWorkspaceName("");
        setIsModalOpen(false);
      } else {
        console.error(`Failed to create ${modalType}`);
      }
    } catch (error) {
      console.error(`Error creating ${modalType}:`, error);
    }
  };

  const getImageSrc = (contentType:any) => {
    switch (contentType) {
      case 1:
        // return "/subworkspace.png";
        // return "/workspace_green.png"
        return "/folder_work.png"
      case 2:
        return "/database.png";
      case 3:
        return "/dset.png";
      case 4:
        return "/files.png";
      case 5:
        return "/workspace.png";
      default:
        return ''; // Return a default image or an empty string if no match
    }
  };

  const getContentTypeLabel = (contentType:any) => {
    
    switch (contentType) {
      case 1:
        return "sub-workspace";
      case 2:
        return "dbconnection";
      case 3:
        return "dataset";
      case 4:
        return "flatfile";
      case 5:
        return "playbook";
      default:
        return "unknown";
    }
  };

  const navigateToPages = (name:string) =>{

    switch (name) {     
      case "DB Connections":    
        navigate(`/newdataconnection/${parentId}`, { state: { mode: mode, parentId : parentId, workspaceName : parentWorkspaceName } });
      break;
      default:
        return;
    }   
  };


  if (error) return <p>Error loading sub-workspaces.</p>;
  if (!subWorkspaces) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>    
      <div className={styles.headerContainer}>
       <Link to="/">
      <button className={styles.backLink}>
        <img  className={styles.homeIcon} src="/home_grey.png" alt="Home" width={20} height={20} />
        <span className={styles.homeText}>Home</span>
        <span >&nbsp;&nbsp;/</span>
      </button>
    </Link>
    </div>
    <div className={styles.headerContainer}>
    <span  className={styles.WorkspaceName} >{parentWorkspaceName}</span>
    </div>

      <div className={styles.heading}>
        <img src="/folder_work.png" height={60} width={60}></img>
        <div>
          <h4>{parentWorkspaceName}</h4>
          <p>Owner {ownerName}</p>
        </div>
      </div>
      <div className={styles.actionButtons}>
        <div className={styles.dropdown}>
          <button className={styles.createWorkspace}>
          <img
            className="addIcon addIconDefault"
            src="/add_green.png"
            alt="Add Users Logo"
            width={20}
            height={20}
          />
          &nbsp;New
            </button>
          <div className={styles.dropdownContent}>
            {[
              { name: "Workspace", icon: getImageSrc(1) },
              { name: "DB Connections", icon: getImageSrc(2) },
              { name: "Dataset", icon: getImageSrc(3) },
              { name: "Flatfile", icon: getImageSrc(4) },
              { name: "Playbook", icon: getImageSrc(5) },
            ]
            .map(
              (item) => (
                <button
                  key={item.name}
                  onClick={() => {
                    setModalType(item.name);

                    if(item.name === "Workspace"){
                      setIsModalOpen(true);

                    }
                    navigateToPages(item.name);
                  }}
                >
                  <img 
                    src={item.icon} 
                    alt={`${item.name} Icon`} 
                    height={17} 
                    width={17} 
                    style={{ marginRight: "15px",transform: "translateY(3px)"}} 
                  />
                  {item.name}
                </button>
              )
            )}
          </div>
        </div>
      </div>
      <div className={styles.workspaceList}>
        {subWorkspaces.length === 0 ? (
          <p className={styles.noWorkspaces}>
            Currently, there are no sub-workspaces created.
          </p>
        ) : (
          <table className={styles.workspaceTable}>
            <thead>
              <tr>
                <th style={{width:"80px",paddingLeft:"60px"}}></th>
                <th style={{paddingLeft:"50px"}}>Name</th>
                <th style={{paddingLeft:"50px"}}>Type</th>
                <th style={{paddingLeft:"50px"}}>Modified</th>
                <th style={{paddingLeft:"50px"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subWorkspaces.map((workspace:any, index:any) => (
                <tr 
                key={workspace.id}
                onClick={() => handleSubWorkspaceClick(workspace.id, workspace.name)}
                onMouseEnter={() => setHoveredRowId(workspace.id)}
                onMouseLeave={() => setHoveredRowId(null)}
                >
                  <td style={{width:"80px",paddingLeft:"60px"}}>
                    <img src={getImageSrc(workspace.contentType)} alt="Content Type Icon" height={20} width={20} />
                  </td>

                  <td style={{paddingLeft:"50px"}} className={styles.nameHyperlink}>{workspace.name}</td>
                  <td style={{paddingLeft:"50px"}}>{getContentTypeLabel(workspace.contentType)}</td>
                  <td style={{paddingLeft:"50px"}}>{new Date(workspace.updatedAt || workspace.createdAt).toLocaleDateString()}</td>
                  <td style={{paddingLeft:"50px"}}>
                    {workspace.roleId < 5 && (
                      <>
                      <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/edit.png"
                              : "/edit_white.png"
                          }
                          alt="Edit"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={`${styles.actionIcon} ${styles.editIcon}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            openEditModal(
                              workspace.id,
                              workspace.name,
                              workspace.parentId
                            );
                          }}
                          height={20}
                          width={20}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="tooltip-text">Rename </span>
                        </div>

                        <div className="Tooltip">
                        <img
                          src={
                            hoveredRowId === workspace.id
                              ? "/delete_red.png"
                              : "/delete_white.png"
                          }
                          alt="Delete"
                          style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                          className={`${styles.actionIcon} ${styles.deleteIcon}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleDeleteWorkspace(workspace.id);
                            deleteWorkspace(workspace.id,workspace.name)
                          }}
                          height={20}
                          width={20}
                        />&nbsp;&nbsp;&nbsp;
                         <span className="tooltip-text">Delete </span>
                         </div>

                      </>
                    )}
                  </td>  
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {showDeleteConfirmation && (
        <div className="modal">
        <div className="modal-content">
            <span className="close" onClick={cancelDelete}>&times;</span>
            <p>Are you sure you want to delete {workspaceName}?</p>
            <div className="modal-buttons">
                <button onClick={cancelDelete} className="modal-cancel">Cancel</button>
                <button onClick={handleDeleteWorkspace} className="modal-confirm">Confirm</button>
            </div>
         </div>
      </div>
     )}            

        {/* Edit Workspace Modal  */}
       {isEditModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Rename</h3>
            <input
              type="text"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder="Workspace Name"
            />
            <button className={styles.cancelButton} onClick={() => setIsEditModalOpen(false)}>Cancel</button>
            <button className={styles.saveButton} onClick={handleEditWorkspace}>Rename</button>
          </div>
        </div>
      )}              

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Name your {modalType}</h3>
            <input
              type="text"
              required
              // value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder={`Enter ${modalType} name`}
              // className={styles.workspaceInput}
            />
            <button className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>Cancel</button>
            <button className={styles.saveButton} onClick={handleCreateItem}>Create</button>
            
          </div>
        </div>
      )}
    </>
  );
};

export default SubWorkspace;


