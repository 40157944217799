import React, { useState, useEffect } from 'react';
import styles from './access.module.css';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import useSWR from 'swr';
import StepSlider from "./Slider.js"

const fetcher = (url:string) => axios.get(url, {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-TENANT-ID": "silzila"
    }
}).then(res => res.data);

const Access = () => {
    const [isModalOpen, setIsModalOpen] = useState<any>(false);
    const [modalContent, setModalContent] = useState<any>('User');
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [addedUserIds, setAddedUserIds] = useState<any>([]);
    const [editedUserIds, setEditedUserIds] = useState<any>([]);
    const { parentId } = useParams();
    const [originalAccessList, setOriginalAccessList] = useState<any>([]);
    const [revertedRows, setRevertedRows] = useState<any>([]);
    const [users, setUsers] = useState<any>([]);
    const [isReverted, setIsReverted] = useState<any>(false);
    const [customPermissions, setCustomPermissions] = useState<any>({});


    const { data: workspaces } = useSWR('https://dev.silzila.com/api/workspace', fetcher);
    const { data: accessList = [], mutate: mutateAccessList } = useSWR(`https://dev.silzila.com/api/workspace/access/${parentId}`, fetcher);

    const parentWorkspace = workspaces?.find((workspace:any) => workspace.id === parentId);
    const parentWorkspaceName = parentWorkspace?.name || "";
    const ownerName = parentWorkspace?.createdBy || "";
    const { data: modalData = [] } = useSWR(
        isModalOpen ? `https://dev.silzila.com/api/access/user-groups/workspace?workspaceId=${parentId}` : null,
        fetcher
    );
    const handleNewButtonClick = () => {
        setIsModalOpen(true);
    };
    useEffect(() => {
        if (accessList.length > 0 && originalAccessList.length === 0) {
            setOriginalAccessList(JSON.parse(JSON.stringify(accessList)));
            const permissions:any = {};
            accessList.forEach((access:any) => {
                if (access.customPermissions && access.customPermissions.length > 0) {
                    permissions[access.id] = {};
                    access.customPermissions.forEach((permission:any) => {
                        permissions[access.id][permission.contentTypeId] = permission.privilegeId;
                    });
                }
            });
            setCustomPermissions(permissions); // Set the customPermissions state based on accessList
        }
    }, [accessList]);
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setSelectedUsers([]);
    };
    const handleAdd = () => {
        const updatedAccessList = [...accessList];
        selectedUsers.forEach((user:any) => {
            const existingUser = updatedAccessList.find(item => item.userGroupId === user.id);
            if (!existingUser) {
                updatedAccessList.push({
                    id: user.id,
                    name: user.name,
                    role: "viewer",
                    isGroup: user.group,
                    resources: Array(5).fill({ view: false, edit: false, create: false }),
                    isBuffered: true,
                });
                setAddedUserIds((prevState:any) => [...prevState, user.id]);
            }
        });
        mutateAccessList(updatedAccessList, false);
        closeModal();
    };
    const handleUserSelection = (userId:any, isGroup:any, userName:any) => {
        setSelectedUsers([{ id: userId, group: isGroup, name: userName }]);
    };
    const handleRoleChange = (userGroupId:any, newRole:any) => {
        const updatedAccessList = accessList.map((item:any) =>
            item.id === userGroupId
                ? { ...item, role: newRole, isChange: true } // Update roleName here
                : item,
        );
        mutateAccessList(updatedAccessList, false);
        setEditedUserIds((prevState:any) => [...prevState, userGroupId]);
        setRevertedRows((prevState:any) => [...prevState, userGroupId]);
    };
    const handlePermissionChange = (userGroupId:any, contentTypeId:any, privilegeId:any) => {
        // Update customPermissions
        setCustomPermissions((prev:any) => ({
            ...prev,
            [userGroupId]: {
                ...prev[userGroupId],
                [contentTypeId]: privilegeId !== null ? Math.min(Math.max(privilegeId, 1), 3) : null // Ensure privilegeId is between 1 and 3, or null
            }
        }));
        // Create a new accessList and update the respective user's customPermissions
        const updatedAccessList = accessList.map((access:any) => {
            if (access.id === userGroupId) {
                // Create or update the customPermissions for the current user
                const updatedPermissions = [
                    {
                        contentTypeId: 2,
                        privilegeId: customPermissions[userGroupId]?.[2] || null
                    },
                    {
                        contentTypeId: 3,
                        privilegeId: customPermissions[userGroupId]?.[3] || null
                    },
                    {
                        contentTypeId: 4,
                        privilegeId: customPermissions[userGroupId]?.[4] || null
                    },
                    {
                        contentTypeId: 5,
                        privilegeId: customPermissions[userGroupId]?.[5] || null
                    }
                ].filter(permission => permission.privilegeId !== null);
                return { ...access, customPermissions: updatedPermissions };
            }
            return access;
        });
        mutateAccessList(updatedAccessList, false);
        console.log("Updated accessList:", updatedAccessList);
    };

    const handleOkClick = async (selectedUsers:any) => {
        const roleMapping :any = {
            "owner": 3,
            "moderator": 4,
            "creator": 5,
            "viewer": 7,
            "custom_creator": 6
        };

        for (const selectedUser of selectedUsers) {
            console.log("accessList", selectedUser)
            if (selectedUser.isBuffered === true || selectedUser.isChange === true || Object.keys(customPermissions).length > 0) {
                const roleId:any = roleMapping[selectedUser.role || selectedUser.roleName];
                let payload = []
                if (roleId === 6) {
                    const userCustomPermissions = customPermissions[selectedUser.id]
                    console.log(userCustomPermissions)
                    payload = [{
                        granteeId: selectedUser.id,
                        isGroup: selectedUser.isGroup,
                        roleId: roleId,
                        customPermissions: [{
                            contentTypeId: 2, //for column db connection
                            privilegeId: userCustomPermissions[2]//rendered dynamically based on the slider moved
                        },
                        {
                            contentTypeId: 3, //for column dataset
                            privilegeId: userCustomPermissions[3] //rendered dynamically based on the slider moved
                        },
                        {
                            contentTypeId: 4, //for column flatfiles
                            privilegeId: userCustomPermissions[4] //rendered dynamically based on the slider moved
                        },
                        {
                            contentTypeId: 5, //for column playbook
                            privilegeId: userCustomPermissions[5] //rendered dynamically based on the slider moved
                        }
                        ]
                    }];
                }
                else {
                    payload = [{
                        granteeId: selectedUser.id,
                        isGroup: selectedUser.isGroup,
                        roleId: roleId
                    }];

                }
                console.log(payload)
                try {
                    let response;
                    const existingUser = accessList.find((item:any) => item.id === selectedUser.id);
                    if (existingUser) {
                        response = await axios.post(`https://dev.silzila.com/api/workspace/user-access?workspaceId=${parentId}`, payload, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "application/json", "X-TENANT-ID": "silzila" } });
                        mutateAccessList();
                        setRevertedRows([]);
                        setEditedUserIds([]);
                        
                    } else {
                        console.log("Adding User")
                        response = await axios.post(`https://dev.silzila.com/api/workspace/user-access?workspaceId=${parentId}`, payload, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "application/json", "X-TENANT-ID": "silzila" } });
                        console.log('User added:', response.data);
                        setAddedUserIds([]);

                    }
                    mutateAccessList(); // Refresh the access list

                } catch (error) {
                    console.error('Error granting access or updating role:', error);
                }
            }
        }
    };
    const handleDelete = async (userGroupId :any, isGroup :any, extraParam:any) => {
        const token = localStorage.getItem('accessToken');
        console.log(accessList)
        try {
            const userOrGroup = accessList.find((item:any) => item.id === userGroupId);// If roleId or roleName are not available in accessList, fetch them dynamically
            let roleId;

            if (userOrGroup && userOrGroup.role) {
                const roleMapping :any = {
                    "owner": 3,
                    "moderator": 4,
                    "creator": 5,
                    "viewer": 7,
                    "custom_creator": 6
                };
                roleId = roleMapping[userOrGroup.role];
            } else {
                // Default roleId if not available
                roleId = 7; // Assuming "viewer" as default
            }
            await axios.delete(`https://dev.silzila.com/api/workspace/access-deletion?workspaceId=${parentId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-TENANT-ID': 'silzila',
                },
                data: [{
                    granteeId: userGroupId,
                    isGroup: isGroup,
                    roleId: roleId
                }]
            });
            mutateAccessList(); 
            console.log('Access deleted');
        } catch (error) {
            console.error('Error deleting access:', error);
        }
    };
    const removeBufferedUser = (id:any) => {
        const updatedAccessList = accessList.filter((item:any) => item.id !== id);
        mutateAccessList(updatedAccessList, false);
        console.log(updatedAccessList);
        mutateAccessList();
        console.log(accessList);
    }

    const handleRowReload = (userGroupId:any) => {
        const originalState = accessList.find((item:any) => item.id === userGroupId);
        if (originalState.isBuffered) {
            removeBufferedUser(originalState.id)
            console.log(accessList)
            mutateAccessList();
        }
        else if (originalState) {
            const updatedAccessList = accessList.map((item:any) =>
                item.id === userGroupId ? originalState : item
            );
            setUsers(users.filter((user:any) => !(user.id === userGroupId && user.isBuffered)));
            setRevertedRows((prevState:any) => prevState.filter((id:any) => id !== userGroupId));
            setAddedUserIds((prevState:any) => prevState.filter((id:any) => id !== userGroupId));
            setEditedUserIds((prevState:any) => prevState.filter((id:any) => id !== userGroupId));
            mutateAccessList(updatedAccessList, false);
            mutateAccessList();
        }
        mutateAccessList(accessList, false);
        // console.log(`Row with ID ${userGroupId} reloaded`);
    };
    const reloadRow = (userGroupId:any) => {
        setRevertedRows((prevState:any) => prevState.filter((id:any) => id !== userGroupId));
        setAddedUserIds((prevState:any) => prevState.filter((id:any) => id !== userGroupId));
        setEditedUserIds((prevState:any) => prevState.filter((id:any) => id !== userGroupId));
    };
    const reloadButtonFunction = () => {
        mutateAccessList();
        const uniqueUserGroupIds = new Set(revertedRows);
        uniqueUserGroupIds.forEach(userGroupId => reloadRow(userGroupId));
        setIsReverted(false);
    }
    const Functions = () => {
        handleNewButtonClick();
        setModalContent('User')
    }
    const renderImage = (id:any) => {
        if (revertedRows.includes(id)) {
            return "/revert_active.png"
        }
        else if (!isReverted) {
            return "/revert_white.png"
        }
    }
    return (
        <>
            <Link to="/">
                <button className={styles.backLink}>Back</button>
            </Link>
            <div className={styles.heading}>
                <img src="/folder_work.png" height={60} width={60} alt="Folder Icon"></img>
                <div>
                    <h4>{parentWorkspaceName}</h4>
                    <p>Created by {ownerName}</p>
                </div>
            </div>
            <div className={styles.actionButtons}>
                <button className={styles.createWorkspace} onClick={Functions}>
                    <img
                        className="addIcon addIconDefault"
                        src="/add_green.png"
                        alt="Add Users Logo"
                        width={20}
                        height={20}
                    />
                    &nbsp;New
                </button>
            </div>
            <div className={styles.resourcesTableContainer}>
                {accessList.length === 0 ? (
                    <p className={styles.noAccessMessage}>
                        Currently, no user/group have access to this particular workspace. Please add user/groups via New button.
                    </p>
                ) : (
                    <div>
                        <table className={styles.resourcesTable}>
                            <thead>
                                <tr>
                                    <th rowSpan={3}>Actions</th>
                                    <th rowSpan={3}>State</th>
                                    <th rowSpan={3}>User/Group</th>
                                    <th rowSpan={3}>Roles</th>
                                    <th colSpan={28}>Resources</th>
                                </tr>
                                <tr>

                                    <th colSpan={4}>Sub Folder</th>
                                    <th colSpan={4}>DB Connection</th>
                                    <th colSpan={4}>Data Files</th>
                                    <th colSpan={4}>Data Sets</th>
                                    <th colSpan={4}>Playbook</th>
                                </tr>
                                <tr>
                                    {Array(5)
                                        .fill(null)
                                        .map((_, index) => (
                                            <React.Fragment key={index}>
                                                <th><img
                                                    src="/none_header.png"
                                                    alt="None"
                                                    height={17}
                                                    width={17}
                                                >
                                                </img></th>
                                                <th><img
                                                    src="/view_header.png"
                                                    alt="View"
                                                    height={17}
                                                    width={17}
                                                >
                                                </img></th>
                                                <th><img
                                                    src="/edit_header.png"
                                                    alt="Edit"
                                                    height={17}
                                                    width={17}
                                                >
                                                </img></th>
                                                <th><img
                                                    src="/create_header.png"
                                                    alt="Create"
                                                    height={17}
                                                    width={17}
                                                >
                                                </img></th>
                                            </React.Fragment>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {accessList.map((access:any) => (
                                    <tr key={access.id}>
                                        <td className={styles.actButton}>
                                            <button className={styles.iconButton} onClick={() => handleRowReload(access.id)}>
                                                <img
                                                    src={renderImage(access.id)}
                                                    alt="Reload Row"
                                                    width={17}
                                                    height={17}>
                                                </img>
                                            </button>
                                            <button className={styles.iconButton} onClick={() => handleDelete(access.id, access.isGroup, access.roleName)}>
                                                <img
                                                    src="/delete_red.png"
                                                    alt="Delete User"
                                                    width={17}
                                                    height={17}>
                                                </img>
                                            </button>
                                        </td>
                                        <td className={styles.stateButton}>
                                            <img
                                                src={addedUserIds.includes(access.id) ? "/add_access_active.png" : "/add_access.png"}
                                                alt="Add Users Logo"
                                                width={17}
                                                height={17}
                                            />
                                            <img
                                                src={editedUserIds.includes(access.id) ? "/edit_access.png" : "/edit_access_white.png"}
                                                alt="edit Users Logo"
                                                width={17}
                                                height={17}
                                            />
                                        </td>
                                        <td>{access.name}</td>
                                        <td>
                                            <select
                                                className={styles.roleDropdown}
                                                onChange={(e) => handleRoleChange(access.id, e.target.value)}
                                                value={access.role}
                                            >
                                                {["owner", "moderator", "creator", "viewer", "custom_creator"].map((role, index) => (
                                                    <option key={index} value={role}>
                                                        {role}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        {Array(5)
                                            .fill(null)
                                            .map((_, index) => {
                                                const privilegeId = customPermissions[access.id]?.[index + 1] || 1; // Default to 1 if no permission is set

                                                return (
                                                    <React.Fragment key={access.id}>
                                                        <td colSpan={4} >
                                                            <StepSlider
                                                                role={access.role}
                                                                column={index + 1} // Start column IDs from 1
                                                                initialValue={privilegeId} // Pass initial privilegeId to StepSlider
                                                                onPermissionChange={(column:any, privilegeId:any) =>
                                                                    handlePermissionChange(access.id, column, privilegeId)
                                                                }
                                                            />
                                                        </td>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.tableButtons}>
                            <div>
                                <button className={styles.reloadButton} onClick={() => reloadButtonFunction()}>Reload</button>
                                <button className={styles.okButton} onClick={() => handleOkClick(accessList)}>Save</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {isModalOpen && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={closeModal}>
                            &times;
                        </span>
                        <div className={styles.modalBody}>
                            <button onClick={() => setModalContent('User')} className={styles.modalButtons}>
                                Users
                            </button>
                            <button onClick={() => setModalContent('Groups')} className={styles.modalButtons}>
                                Groups
                            </button>

                            {modalContent === 'User' && (
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalData.filter((user:any) => !user.isGroup).map((user:any) => (
                                            <tr key={user.id}>
                                                <td>
                                                    <input
                                                        type="radio"
                                                        checked={selectedUsers.some((selectedUser:any) => selectedUser.id === user.id)}
                                                        onChange={() => handleUserSelection(user.id, user.isGroup, user.name)}
                                                    />
                                                </td>
                                                <td><img src={user.profileImage ? `data:image/jpeg;base64,${user.profileImage}` : "/default.png"} alt="Users Icon" height={17} width={17}></img></td>
                                                <td>{user.name}</td>
                                                <td>{user.emailId}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}

                            {modalContent === 'Groups' && (
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalData.filter((group:any) => group.isGroup).map((group:any) => (
                                            <tr key={group.id}>
                                                <td>
                                                    <input
                                                        type="radio"
                                                        checked={selectedUsers.some((selectedUser:any) => selectedUser.id === group.id)}
                                                        onChange={() => handleUserSelection(group.id, group.isGroup, group.name)}
                                                    />
                                                </td>
                                                <td><img src="/groups.png" alt="Group Icon" height={17} width={17}></img></td>
                                                <td>{group.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className={styles.modalFooter}>
                            <button className={styles.addButton} onClick={handleAdd}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default Access;

