import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useSWR from 'swr';
import './groups.css';
import { Link } from 'react-router-dom';

const fetcher = (url:any, axiosInstance:any) => axiosInstance.get(url).then((res:any) => res.data);

const GroupName = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<any>('');
    const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
    const [sortConfig, setSortConfig] = useState<any>({ key: '', direction: 'asc' });
    const [groupIndexToDelete, setGroupIndexToDelete] = useState<any>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<any>(false);
    const [groupToDelete, setGroupToDelete] = useState<any>(null);
    const [groupToRename, setGroupToRename] = useState<any>(null);
    const [newGroupName, setNewGroupName] = useState<any>('');
    const [showCreateGroupDialog, setShowCreateGroupDialog] = useState<any>(false);
    const [renameError, setRenameError] = useState<any>('');
    const[isLoading,setIsLoading]=useState<any>(true);

    const accessToken = localStorage.getItem('accessToken');

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-TENANT-ID': 'silzila',
            'Content-type': 'application/json'
        }
    });

    const { data: groups = [], mutate,error } = useSWR('https://dev.silzila.com/api/groups/list', url => fetcher(url, axiosInstance));
    useEffect(() => {
        if (groups.length > 0 || groups.length === 0) {
            setIsLoading(false);
        }
    }, [groups]);

    if (error) {
        setIsLoading(false);
        console.error('Error fetching groups:', error);
    }
    
    const filteredGroups:any = groups
        .filter((group:any) => group.groupName.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a:any, b:any) => {
            if (a.id === 1) return -1;
            if (b.id === 1) return 1;
            let aValue, bValue;

            if (sortConfig.key === 'groupName') {
                aValue = a.groupName.toLowerCase();
                bValue = b.groupName.toLowerCase();
            } else if (sortConfig.key === 'userCount') {
                aValue = a.userCount;

            }

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

    const deleteGroup = (index:any) => {
        setGroupIndexToDelete(index);
        setGroupToDelete(groups[index]);
        setShowDeleteConfirmation(true);
    };

    const confirmDeleteGroup = async () => {
        try {
            const groupToDelete = filteredGroups[groupIndexToDelete];
            if (!groupToDelete.id) throw new Error("Group ID is missing");

            await axiosInstance.delete(`https://dev.silzila.com/api/groups/${groupToDelete.id}`);
            mutate();
        } catch (error) {
            console.error('There was an error deleting the group:', error);
            alert('Error deleting group. Please try again.');
        } finally {
            setShowDeleteConfirmation(false);
            setGroupIndexToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
        setGroupIndexToDelete(null);
    };

    const handleMouseEnter = (index:any) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    const handleGroupClick = (groupid:any, groupName:any) => {
        navigate(`/group-details/${groupid}`, { state: { groupName } });
        console.log(groupName);
    };

    const handleSort = (key:any) => {
        const newDirection = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction: newDirection });
    };

    const getSortIcon = (key:any) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? (
                <img className='headerIcon' src="/sort-up.png" alt="Ascending" width={20} height={20} />
            ) : (
                <img className='headerIcon' src="/sort-down.png" alt="Descending" width={20} height={20} />
            );
        }
        return <img className='headerIcon' src="/sort.png" alt="Default" width={20} height={20} />;
    };

    const handleCreateGroup = async () => {
        if (!newGroupName.trim()) {
            alert('Group name cannot be empty.');
            return;
        }

        try {
            await axiosInstance.post(`https://dev.silzila.com/api/groups/${newGroupName}`);
            mutate(); // Refetch the groups to update the list

            setShowCreateGroupDialog(false);
            setNewGroupName('');
        } catch (error) {
            console.error('Error creating group:', error);
            alert('Error creating group. Please try again.');
        }
    };

    const handleGroupNameChange = (e:any) => {
        setNewGroupName(e.target.value);
    };

    const handleCloseDialog = () => {
        setShowCreateGroupDialog(false);
        setNewGroupName('');
    };

    const handleRenameClick = (group:any) => {
        setGroupToRename(group);
        setNewGroupName(group.groupName);
    };

    const handleRenameCancel = () => {
        setGroupToRename(null);
        setNewGroupName('');
        setRenameError('');
    };

    const handleRenameConfirm = async () => {
        if (!newGroupName.trim()) {
            setRenameError('Group name cannot be empty');
            return;
        }

        try {
            await axiosInstance.put('https://dev.silzila.com/api/groups/update', {
                id: groupToRename.id,
                name: newGroupName
            });

            mutate(); // Refetch the groups to update the list
            setGroupToRename(null);
            setNewGroupName('');
            setRenameError('');
        } catch (error) {
            console.error('Error renaming group:', error);
            alert('Error renaming group. Please try again.');
        }
    };
    
    return (
        <>
            <Link to='/'><button className='back-link-group'>Back</button></Link>
            <div className='groupsContainer'>
                <div className="searchContainer-groups">
                    <input
                        type="text"
                        placeholder="Search Groups"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="searchInput"
                    />
                    <img src="/glass.png" alt="Search Icon" className="searchIcon" width={20} height={20} />
                </div>

                <div className="actionButtons">
                    <button
                        className="button group"
                        // onClick={() => setShowCreateGroupDialog(true)}
                        onClick={() => setShowCreateGroupDialog(true)}
                    >
                        <img className='addIcon addIconDefault' src='/groups_green.png' alt="Create Group" height={20} width={20} />
                        <img className='addIcon addIconHover' src='/groups_white.png' alt="Create Group" height={20} width={20} />
                        Create Group
                    </button>
                </div>
                {isLoading ? ( 
                <div className="loading-container">
                    <div className="spinner"></div>
                </div>
                ) : filteredGroups.length === 0 ? (
                    <p>No groups have been created yet.</p>
                ) : (
                // {filteredGroups.length === 0 ? (
                //     <p>No groups have been created yet.</p>
                // ) : (
                    <div className='tableContainer'>
                        <table className="groupsTable">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('groupName')}>
                                        Group Name {getSortIcon('groupName')}
                                    </th>
                                    <th onClick={() => handleSort('userCount')}>
                                        Total Users {getSortIcon('userCount')}
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredGroups.map((group:any, index:any) => (
                                    <tr
                                        key={index}
                                        onClick={() => handleGroupClick(group.id, group.groupName)}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        className={index === hoveredIndex ? 'hoveredRow' : ''}
                                        style={{ cursor: 'pointer' }}
                                    >
                                       <td className='nameHyperlink'>
                                            {group.id === 1 && (
                                                <img
                                                    src='/crown.png' 
                                                    alt="All Users Icon"
                                                    height={20}
                                                    width={20}
                                                    style={{ marginRight: '5px' ,position:'relative',top:'3px'}} 
                                                />
                                            )}
                                            {group.groupName}
                                        </td>
                                        <td>{group.userCount}</td>
                                        <td className="action-container">
                                            {group.id !== 1 && (
                                                <>
                                                    <div className="Tooltip">
                                                        <button
                                                            className='Tooltip'
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent triggering the row click
                                                                deleteGroup(index);
                                                            }}
                                                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                                        >
                                                            <img
                                                                src={hoveredIndex === index ? '/delete_red.png' : '/delete_white.png'}
                                                                alt="Delete Group"
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </button>
                                                        <span className="tooltip-text">Delete Group</span>
                                                    </div>

                                                    <div className="Tooltip">
                                                        <button
                                                            className='Tooltip'
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent triggering the row click
                                                                handleRenameClick(group);
                                                            }}
                                                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', marginLeft: '10px' }} // Add margin or other styles as needed
                                                        >
                                                            <img
                                                                src={hoveredIndex === index ? '/edit.png' : '/edit_white.png'}
                                                                alt="Rename Group Name"
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </button>
                                                        <span className="tooltip-text">Rename Group Name</span>
                                                    </div>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {showDeleteConfirmation && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={cancelDelete}>&times;</span>
                            <p>Are you sure you want to delete {groupToDelete?.groupName}?</p>
                            <div className="modal-buttons">
                                <button onClick={cancelDelete} className="modal-cancel">Cancel</button>
                                <button onClick={confirmDeleteGroup} className="modal-confirm">Confirm</button>
                            </div>
                        </div>
                    </div>
                )}

                 {showCreateGroupDialog && (
                    <div className='modal'>
                        <div className='modal-content'>
                            <span className='close' onClick={handleCloseDialog}>&times;</span>
                            <p>Create Group</p>
                            <input
                                type='text'
                                placeholder='Enter group name'
                                value={newGroupName}
                                onChange={handleGroupNameChange}
                            />
                            <div className="modal-buttons">
                                <button className="modal-create"onClick={handleCreateGroup}>Confirm</button>
                                <button className="modal-cancel" onClick={handleCloseDialog}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}


                {groupToRename && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleRenameCancel}>&times;</span>
                            <p>Rename Group: {groupToRename.groupName}</p>
                            <input
                                type="text"
                                value={newGroupName}
                                onChange={handleGroupNameChange}
                            />
                            {renameError && <p className="error">{renameError}</p>}
                            <div className="modal-buttons">
                                <button onClick={handleRenameCancel} className="modal-cancel">Cancel</button>
                                <button onClick={handleRenameConfirm} className="modal-create">Rename</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default GroupName;
