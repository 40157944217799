import React from 'react';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import SubWorkspaceDetails from '../Components/SubWorkspaceDetails';

const SubWorkDetails = () => {
    return (
        <>
            <Header />
            <Navbar />
            <SubWorkspaceDetails/>
        </>
    )
}
export default SubWorkDetails;