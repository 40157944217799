import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './userprof.module.css';

const UserProf = () => {
    const [image, setImage] = useState('./default.png');
    const navigate = useNavigate();

    const handleImageChange = (e:any) => {
        if (e.target.files.length !== 0) {
            const file = e.target.files[0];
            setImage(URL.createObjectURL(file));
        }
    };

    const handleImageDelete = () => {
        setImage('./default.png');
    };

    return (
        <div className={styles.formContainer}>
            <Link to='/admin/users'>
                <button className={styles.backLink}>Back</button>
            </Link>
            <div className={styles.photoContainer}>
                {image === './default.png' ? (
                    <div className={styles.defaultState}>
                        <img src='./default.png' alt="Default" className={styles.defaultPreview} />
                        <label className={styles.fileInputLabel}>
                            Choose File
                            <input type="file" onChange={handleImageChange} className={styles.fileInput} />
                        </label>
                    </div>
                ) : (
                    <div className={styles.imagePreviewContainer}>
                        <img src={image} alt="Uploaded" className={styles.photoPreview} />
                        <div className={styles.imageInfo}>
                            <img
                                src='./cross.png'
                                alt='Delete'
                                className={styles.deleteIcon}
                                onClick={handleImageDelete}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.formContent}>
                <div className={styles.userDetails}>
                    <form>
                        <div className={styles.inputRow}>
                            <div className='flex'>
                                <input type='text' name='FirstName' placeholder='First Name*' className={`${styles.inputField} ${styles.firstRow}`} />
                                <input type='text' placeholder='Last Name' className={styles.inputField} />
                            </div>
                        </div>
                        <div className={styles.inputRow}>
                            <input type='number' name='tenantid' placeholder='Tenant ID*' className={`${styles.inputField} ${styles.row}`} />
                        </div>
                        <div className={styles.inputRow}>
                            <input type='email' name='email' placeholder='Email Address*' className={`${styles.inputField} ${styles.row}`} />
                        </div>
                        <div className={styles.inputRow}>
                            <input type='text' name='companyname' placeholder='Company Name*' className={`${styles.inputField} ${styles.row}`} />
                        </div>
                        <div className={styles.buttonRow}>
                            <button type='button' className={styles.cancelButton} onClick={() => navigate('/admin-page')}>Back</button>
                            <button type='submit' className={styles.addButton}>Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserProf;
