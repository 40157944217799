"use client"
import React from 'react';
import Header from '../Components/Header';
import Navbar from '../Components/Navbar';
import GroupName from '../Components/GroupName';

const Groups = () => {
    
    return (
        <>
            <Header />
            <Navbar />
            <GroupName />
        </>
    );
}

export default Groups;
