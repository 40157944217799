import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './navbar.module.css';

const Navbar = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <header className={styles.header}>
            <div className={styles.buttonRow}>
                <div className={styles.usersContainer}>
                    <Link to="/admin/users" className={`${styles.button} ${currentPath === '/admin/users' ? styles.active : ''}`}>
                        <img src="/user.png" alt="Users" width={20} height={20} />
                        <span className={styles.buttonText}>Users</span>
                        {/* <img src="/info.png" alt="Info" width={13} height={13} className={styles.infoIcon} /> */}
                        {/* <div className={styles.tooltip}>
                        This section lists the users that have been added to your organisation account. Click/Hover on a user to view detailed information about the particular user to change any specific user-settings.
                    </div>    */}
                    </Link>
                </div>
                <div className={styles.groupsContainer}>
                    <Link to="/admin/groups" className={`${styles.button} ${currentPath === '/admin/groups' ? styles.active : ''}`}>
                        <img src="/groups.png" alt="Groups" width={20} height={20} />
                        <span className={styles.buttonText}>Groups</span>
                    </Link>
                </div>

                <div className={styles.adminsContainer}>
                    <Link to="/admin/admins" className={`${styles.button} ${currentPath === '/admin/admins' ? styles.active : ''}`}>
                        <img src="/admin.png" alt="Admin" width={20} height={20} />
                        <span className={styles.buttonText}>Admins</span>
                    </Link>
                </div>
                {/* <div className={styles.workContainer}>
                    <Link to="/home/workspace" className={`${styles.button} ${currentPath === '/home/workspace' ? styles.active : ''}`}>
                        <img src="/workspace.png" alt="Workspace" width={20} height={20} />
                        <span className={styles.buttonText}>Workspace</span>
                    </Link>
                </div> */}
            </div>
        </header>
    );
};

export default Navbar;

