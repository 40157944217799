import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './adduser.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const AddUser = () => {
    const [fName, setFirstName] = useState('');
    const [lName, setLastName] = useState('');
    const [emailId, setEmail] = useState('');
    const [pword, setPassword] = useState('');
    const [image, setImage] = useState('/default.png');
    const [photo, setPhoto] = useState(null);
    const [errors, setErrors] = useState<any>({});
    const [showPassword, setShowPassword] = useState(false);
    const [imageName, setImageName] = useState('');
    const navigate = useNavigate();


    //const token ="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhY2hpbnR5YS5yYWpAc2lsemlsYS5jb20iLCJhdWRpZW5jZSI6IndlYiIsImFjY2VzcyI6ImFjY291bnRfYWRtaW4iLCJpYXQiOjE3MjQ5MDg5MDEsInRlbmFudCI6InNpbHppbGEiLCJleHAiOjE3MjQ5NjY1MDF9.pYy1tnBjhJKAKzzWsPd5AdrYfL8ipDOYxu4-j-We7aQaNyI8COZYxDupyuhOsQ7grASCJyVbAVmTqgrzhD2VPQ"; // Retrieve token from local storage

    // localStorage.setItem('accessToken', token)
    // console.log(token)

    const handleSubmit = async (event:any) => {
        event.preventDefault();

        const newErrors:any = {};
        if (!fName) newErrors.firstName = 'First name is required';
        if (!emailId) newErrors.email = 'Email is required';
        if (!pword) newErrors.password = 'Password is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        let profileImage;

        if (photo) {
            profileImage = photo;
        } else {
            const defaultImageResponse = await fetch("/default.png");
            const defaultImageBlob = await defaultImageResponse.blob();
            profileImage = new File([defaultImageBlob], "/default.png", { type: "image/png" });
        }

        const formData = {
            firstName: fName,
            lastName: lName,
            email: emailId,
            password: pword,
            isTemp: true,
            profileImage: profileImage
        }
        console.log(formData)

        try {
            await axios.post(
                'https://dev.silzila.com/api/user/add',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        'X-TENANT-ID': 'silzila'
                    }
                }
            );

            // Reset form fields
            setFirstName('');
            setLastName('');
            setEmail('');
            setPassword('');
            setImage('/default.png');
            setPhoto(null);
            setErrors({});
            navigate('/admin/users');
        } catch (error) {
            console.error('There was an error adding the user:', error);
            // Optionally, handle errors more gracefully
        }
    };

    const handleImageChange = (event:any) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            setImageName(file.name);
            setPhoto(file);
            console.log(URL.createObjectURL(file))
        }
    };

    const handleImageDelete = () => {
        setImage('/default.png');
        setImageName('');
        setPhoto(null);
    };

    return (
        <div className='form-container'>
            <Link to='/admin/users'><button className='back-link'>Back</button></Link>
            <p className='text'>Please fill the details to add the user</p>
            <div className='form-content'>
                <div className='photo-container'>
                    {image === '/default.png' ? (
                        <div className='default-state'>
                            <img src='/default.png' alt="Default" className='default-preview' />
                            <label className='file-input-label'>
                                Choose File
                                <input type="file" onChange={handleImageChange} className='file-input' />
                            </label>
                        </div>
                    ) : (
                        <div className='image-preview-container'>
                            <img src={image} alt="Uploaded" className='photo-preview' />
                            <div className='image-info'>
                                <span>{imageName}</span>
                                <img src='/cross.png' alt='Delete' className='delete-icon' onClick={handleImageDelete} />
                            </div>
                        </div>
                    )}
                </div>
                <div className='user-details'>
                    <form onSubmit={handleSubmit}>
                        <div className='input-row'>
                            <div className='flex'>
                                <input type='text' name='FirstName' placeholder='First Name*' className='input-field first-row' required value={fName} onChange={(e) => setFirstName(e.target.value)} />
                                <input type='text' placeholder='Last Name' className='input-field' value={lName} onChange={(e) => setLastName(e.target.value)} />
                                {errors.firstName && <p className='error'>{errors.firstName}</p>}
                            </div>
                        </div>
                        <div className='input-row'>
                            <input type='email' name='email' placeholder='Email Address*' className='input-field' value={emailId} onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && <p className='error'>{errors.email}</p>}
                        </div>
                        <div className='input-row password-container'>
                            <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Password*' className='input-field' value={pword} onChange={(e) => setPassword(e.target.value)} />
                            <button type='button' className='eye-button' onMouseOver={() => setShowPassword(true)} onMouseDown={() => setShowPassword(false)} onMouseLeave={() => setShowPassword(false)}>
                                <img src='/eye.png' height="15px" width="15px" alt="Show Password"></img>
                            </button>
                            {errors.password && <p className='error'>{errors.password}</p>}
                        </div>
                        <div className='button-row'>
                            <button type='button' className='cancel-button' onClick={() => { setFirstName(''); setLastName(''); setEmail(''); setPassword(''); setPhoto(null); setErrors({}); }}>Cancel</button>
                            <button type='submit' className='add-button'>Add</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddUser;

