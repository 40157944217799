import React from 'react';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import AdminList from '../Components/Adminlist';

const  Admins=()=>{
    return(
        <>
            <Header/>
            <Navbar/>
            <AdminList/>
        </>
    )
}

export default Admins;