import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const StepSlider = ({ role, column,initialValue, onPermissionChange }) => {
  const [sliderValue, setSliderValue] = useState(initialValue);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (role === 'owner') {
      setSliderValue(100); // Full privileges (Create)
      setDisabled(true); // Disable slider for owner
    } else if (role === 'moderator' || role === 'creator') {
      if (column === 1) {
        setSliderValue(50); // View for first column (dbconnection)
      } else {
        setSliderValue(100); // Create for other columns
      }
      setDisabled(true); // Disable slider for standard roles
    } else if (role === 'viewer') {
      setSliderValue(50); // View only
      setDisabled(true); // Disable slider for viewer
    } else if (role === 'custom_creator') {
      if (column === 1) {
        setSliderValue(50);
        setDisabled(true);
      }
      else {
        setDisabled(false);
        if (column === 2) {
          console.log(column,sliderValue)
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider
        } else if (column === 3) {
          console.log(column,sliderValue)
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider for other columns
        }
         else if (column === 4) {
          console.log(column,sliderValue)
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider
        } else if (column === 5) {
          console.log(column,sliderValue)
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider for other columns
        }
      }
    } else {
      setSliderValue(25); // None (for any unhandled role)
      setDisabled(false); // Default to disabled slider
    }
  }, [role, column, sliderValue]);

  const handleChange = (e, newValue) => {
    console.log("value changed to:",newValue);
    setSliderValue(newValue);
    onPermissionChange(column, mapSliderValueToPrivilegeId(newValue));
  };

  const mapPrivilegeIdToSliderValue = (privilegeId) => {
    switch (privilegeId) {
      case 1:
        return 100; // Create
      case 2:
        return 75;  // Edit
      case 3:
        return 50;  // View
      default:
        return 25;  // None
    }
  };

  const mapSliderValueToPrivilegeId = (value) => {
    switch (value) {
      case 25:
        return null; // None
      case 50:
        return 3; // View
      case 75:
        return 2; // Edit
      case 100:
        return 1; // Create
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: 150, marginLeft: 2, color: 'black' }}>
      <Slider
        value={sliderValue}
        step={25}
        min={25}
        max={100}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => {
          switch (value) {
            case 25:
              return 'None';
            case 50:
              return 'View';
            case 75:
              return 'Edit';
            case 100:
              return 'Create';
            default:
              return '';
          }
        }}
        aria-label="Slider"
        disabled={disabled}
        onChange={handleChange}
      />
    </Box>
  );
};

export default StepSlider;
