import React from 'react';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import Access from '../Components/Access';

const AccessPage=()=>{
    return(
        <>
            <Header/>
            <Navbar/>
            <Access/>
        </>
    )
}
export default AccessPage;