import React from 'react';
import User from '../Components/User';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';


const AdminUser = () => {
  return (
    <>
      <Header />
      <Navbar />
      <User />
    </>
  );
}

export default AdminUser;
