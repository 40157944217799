'use client'
import { useState, useEffect } from 'react'; // Import useEffect here
import axios from 'axios';
import useSWR from 'swr'; // Import SWR
import styles from './adminlist.module.css';

// Define a fetcher function
const fetcher = (url:any) =>
  axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);


  const axiosInstance = axios.create({
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'X-TENANT-ID': 'silzila',
      'Content-type': 'application/json'
    }
  });

const AdminList = () => {
  const [showAddAdminDialog, setShowAddAdminDialog] = useState(false);
  const [modalSelectedUsers, setModalSelectedUsers] = useState<any>([]);
  const [activeTab, setActiveTab] = useState('users');
  const [modalSelectedGroups, setModalSelectedGroups] = useState<any>([]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [userIndexToRemove, setUserIndexToRemove] = useState(null);
  const [adminToRemove, setAdminToRemove] = useState<any>(null);

  // Use SWR for admins
  const { data: admins = [], error: adminsError, mutate: mutateAdmins } = useSWR(
    'https://dev.silzila.com/api/admin/list',
    fetcher
  );

  // Use SWR for users
  const { data: allUsers = [], error: usersError } = useSWR(
    activeTab === 'users' ? 'https://dev.silzila.com/api/users/list' : null,
    fetcher
  );

  // Use SWR for groups
  const { data: allGroups = [], error: groupsError } = useSWR(
    activeTab === 'groups' ? 'https://dev.silzila.com/api/allgroups' : null,
    fetcher
  );
  useEffect(() => {
    const fetchProfileImages = async () => {
      if (allUsers.length > 0) {
        const usersWithImages = await Promise.all(allUsers.map(async (user:any) => {
          try {
            const imageResponse = await axiosInstance.get(`https://dev.silzila.com/api/user/profile-image`, {
              params: { userId: user.id }
            });

            let profileImageUrl;
            if (imageResponse.data) {
              profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
            } else {
              profileImageUrl = '/default.png';
            }
            console.log(profileImageUrl)
            return { ...user, profileImage: profileImageUrl };

          } catch (error) {
            console.error(`Failed to fetch image for user ${user.id}:`, error);
            return { ...user, profileImage: '/default.png' };
          }
        }));
      }
    };

    fetchProfileImages();
  }, [allUsers]);

  const toggleModalSelectGroup = (index:any) => {
    setModalSelectedGroups((prevSelected:any) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i:any) => i !== index)
        : [...prevSelected, index]
    );
  };

  const confirmRemoveUser = async () => {
    try {
      const adminToRemove:any = admins.find((admin:any) => admin.id === userIndexToRemove);
      if (!adminToRemove) {
        console.error('Admin not found for deletion.');
        return;
      }

      const response = await axios.delete(`https://dev.silzila.com/api/admin/delete`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: {
          granteeId: adminToRemove.id,
          isGroup: adminToRemove.isGroup || false  // Make sure this is set correctly
        }
      });
      mutateAdmins();

    } catch (error:any) {
      if (error.response) {
        console.error('Axios Error - Response:', error.response);
      } else if (error.request) {
        console.error('Axios Error - No Response Received:', error.request);
      } else {
        console.error('Axios Error - Other:', error.message);
      }
    } finally {
      setShowRemoveConfirmation(false);
      setUserIndexToRemove(null);
    }
  };

  const removeUser = (adminId:any) => {
    setUserIndexToRemove(adminId);
    const admin = admins.find((admin:any) => admin.id === adminId);
    setAdminToRemove(admin);
    setShowRemoveConfirmation(true);
  };

  const cancelRemove = () => {
    setShowRemoveConfirmation(false);
    setUserIndexToRemove(null);
  };

  const handleAddAdminConfirm = async () => {
    try {
      // Determine if the active tab is for users or groups
      const isGroup = activeTab === 'groups';
      const selectedIndex = isGroup ? modalSelectedGroups[0] : modalSelectedUsers[0];
      const selectedId = isGroup ? allGroups[selectedIndex].id : allUsers[selectedIndex].id;

      console.log('Selected ID:', selectedId, 'Is Group:', isGroup);

      const response = await axios.post(
        'https://dev.silzila.com/api/user/admin',
        {
          granteeId: selectedId,
          isGroup: isGroup,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Response Status:', response.status);
      console.log('Response Data:', response.data);

      if (response.status === 201) {
        setShowAddAdminDialog(false);
        setModalSelectedUsers([]);
        setModalSelectedGroups([]);
        mutateAdmins();
      } else {
        console.error('Failed to add admin. Response data:', response.data);
        setModalSelectedUsers([]);
        setModalSelectedGroups([]);
        mutateAdmins();
      }
    } catch (error:any) {
      if (error.response) {
        console.error('Axios Error - Response:', error.response);
      } else if (error.request) {
        console.error('Axios Error - No Response Received:', error.request);
      } else {
        console.error('Axios Error - Other:', error.message);
      }
    }
  };

  const toggleModalSelectUser = (index:any) => {
    setModalSelectedUsers((prevState:any) =>
      prevState.includes(index) ? prevState.filter((i:any) => i !== index) : [...prevState, index]
    );
  };

  if (adminsError) return <p>Error loading admins.</p>;
  if (usersError) return <p>Error loading users.</p>;
  if (groupsError) return <p>Error loading groups.</p>;

  return (
    <div className={styles.container}>
      <button className={styles.addButton} onClick={() => setShowAddAdminDialog(true)}>
        <img className={`${styles.addIconDefault} addIconDefault`} src="/add_purple.png" alt="Add Admin Logo" width={20} height={20} />
        <img className={`${styles.addIconHover} addIconHover`} src="/add_white.png" alt="Add Admin Logo Hover" width={20} height={20} />
        Add Admin
      </button>
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ width: '80px' }}></th>
            <th style={{ width: '180px' }}>Name</th>
            <th style={{ width: '200px' }}>Email</th>
            <th style={{ width: '120px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin:any) => (
            <tr key={admin.id}>
              <td>
                <img
                  src={admin.isGroup ? '/groups.png' : (admin.photo || '/default.png')}
                  width={20}
                  height={20}
                  style={{ marginLeft: '80px' }}
                />
              </td>
              <td>{admin.name}</td>
              <td>{admin.isGroup ? '-' : admin.emailId}</td>
              <td>
                <div className="Tooltip">
                  <button className={styles.actionButton} onClick={() => removeUser(admin.id)}>
                    <img src="/cross_red.png" alt="Remove Admin" width={20} height={20} />
                  </button>
                  <span className="tooltip-text">Remove Admin</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showAddAdminDialog && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span
              className={styles.close}
              onClick={() => setShowAddAdminDialog(false)}
            >
              &times;
            </span>
            <div className={styles.tabContainer}>
              <button
                style={{ marginLeft: '20px' }}
                className={activeTab === 'users' ? styles.activeTab : ''}
                onClick={() => setActiveTab('users')}
              >
                Users
              </button>
              <button
                style={{ paddingLeft: '20px' }}
                className={activeTab === 'groups' ? styles.activeTab : ''}
                onClick={() => setActiveTab('groups')}
              >
                Groups
              </button>
            </div>
            {activeTab === 'users' && (
              <div className={styles.userList}>
                <p>Select users to add as admin:</p>
                {allUsers.map((user:any, index:any) => (
                  <div key={index} className={styles.userItem}>
                    <input
                      type="radio"
                      name="user"
                      checked={modalSelectedUsers.includes(index)}
                      onChange={() => toggleModalSelectUser(index)}
                    />
                    <img src={user.profileImage ? `data:image/jpeg;base64,${user.profileImage}`:"/default.png"} width={30} height={30} />
                    <span>
                      {user.firstName} {user.lastName} &nbsp; ({user.email})
                    </span>
                  </div>
                ))}
              </div>
            )}
            {activeTab === 'groups' && (
              <div className={styles.groupList}>
                <p>Select groups to add as admin:</p>
                {allGroups.map((group:any, index:any) => (
                  <div key={index} className={styles.groupItem}>
                    <input
                      type="radio"
                      name="group"
                      checked={modalSelectedGroups.includes(index)}
                      onChange={() => toggleModalSelectGroup(index)}
                    />
                    <span>{group.groupName}</span>
                  </div>
                ))}
              </div>
            )}
            <button onClick={handleAddAdminConfirm}>Add Admin</button>
          </div>
        </div>
      )}
      {showRemoveConfirmation && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={cancelRemove}>&times;</span>
            <p>Are you sure you want to remove {adminToRemove.name} as admin?</p>
            <div className="modal-buttons">
              <button onClick={cancelRemove} className="modal-cancel">Cancel</button>
              <button onClick={confirmRemoveUser} className="modal-confirm">Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminList;


